/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'
// import axios from 'axios';

export default {

  // Fetch
  fetchItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/reminders', {})
        .then((response) => {
          commit('SET_ITEMS', response.data.values)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchHistories({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/reminders/histories', {})
        .then((response) => {
          commit('SET_HISTORIES', response.data.values)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  detailItem({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/reminders/${payload.id}`, {})
        .then((response) => {
          commit('SET_ITEM', response.data.values)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  storeItem({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/reminders', payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error);
        })
    })
  },

  checkItem({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/reminders/${payload.id}/check`, payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error);
        })
    })
  },

  updateItem({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/reminders/${payload.id}`, payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeItem({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/reminders/${payload.id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
