import mock from '@/fake-db/mock.js'

const data = {
  all: [
    {
      "id": 1,
      "tanggal_terima": "06-01-2015",
      "penerima": "Maulana",
      "nomor_surat": "-",
      "tanggal_surat": "-",
      "nama_perusahaan": "Bank Mandiri",
      "alamat": "-",
      "cp": "-",
      "telp_fax": "-",
      "perihal": "Bp. Aziz M. Purwantri Putra",
      "page": 1,
      "tujuan_surat": "PT. Arga Solusi",
      "lokasi_file": "Umi",
      "file": ""
    },
    {
      "id": 2,
      "tanggal_terima": "06-01-2015",
      "penerima": "Maulana",
      "nomor_surat": "-",
      "tanggal_surat": "-",
      "nama_perusahaan": "Bank Mandiri",
      "alamat": "-",
      "cp": "-",
      "telp_fax": "-",
      "perihal": "Bp. Fatkhur Rachman",
      "page": 1,
      "tujuan_surat": "PT. Arga Solusi",
      "lokasi_file": "Bp. Fatkhur",
      "file": "null"
    },
    {
      "id": 3,
      "tanggal_terima": "07-01-2015",
      "penerima": "Sudiyarto",
      "nomor_surat": "-",
      "tanggal_surat": "-",
      "nama_perusahaan": "Indosat",
      "alamat": "-",
      "cp": "-",
      "telp_fax": "-",
      "perihal": "Formulir Print Tagihan Untuk Bulan Oktober - Desember 2014",
      "page": 1,
      "tujuan_surat": "PT. Arga Solusi",
      "lokasi_file": "Surat Masuk / Permohonan",
      "file": ""
    },
    {
      "id": 4,
      "tanggal_terima": "07-01-2015",
      "penerima": "Maulana",
      "nomor_surat": "-",
      "tanggal_surat": "-",
      "nama_perusahaan": "Bank Mega",
      "alamat": "Menara Bank Mega, Jl. Kapten Tendean 12-14A, Jakarta 12791\r\n",
      "cp": "-",
      "telp_fax": "-",
      "perihal": "-",
      "page": 2,
      "tujuan_surat": "PT. Arga Solusi",
      "lokasi_file": "Ibu Ira",
      "file": ""
    },
    {
      "id": 5,
      "tanggal_terima": "07-01-2015",
      "penerima": "Maulana",
      "nomor_surat": "-",
      "tanggal_surat": "-",
      "nama_perusahaan": "Bank BRI",
      "alamat": "-",
      "cp": "-",
      "telp_fax": "-",
      "perihal": "-",
      "page": 1,
      "tujuan_surat": "PT. Arga Solusi",
      "lokasi_file": "Ibu Ira",
      "file": ""
    },
    {
      "id": 6,
      "tanggal_terima": "07-01-2015",
      "penerima": "Sudiyarto",
      "nomor_surat": "-",
      "tanggal_surat": "-",
      "nama_perusahaan": "Telkomsel",
      "alamat": "-",
      "cp": "-",
      "telp_fax": "-",
      "perihal": "Formulir Print Tagihan Untuk Bulan Oktober - Desember 2014",
      "page": 1,
      "tujuan_surat": "PT. Arga Solusi",
      "lokasi_file": "Surat Masuk / Permohonan",
      "file": ""
    },
    {
      "id": 7,
      "tanggal_terima": "07-01-2015",
      "penerima": "Sudiyarto",
      "nomor_surat": "-",
      "tanggal_surat": "-",
      "nama_perusahaan": "Bank BRI",
      "alamat": "-",
      "cp": "-",
      "telp_fax": "-",
      "perihal": "-",
      "page": 2,
      "tujuan_surat": "PT. Arga Solusi",
      "lokasi_file": "Ibu Ira",
      "file": ""
    },
    {
      "id": 8,
      "tanggal_terima": "13-01-2015",
      "penerima": "Maulana",
      "nomor_surat": "-",
      "tanggal_surat": "-",
      "nama_perusahaan": "Bank Mandiri",
      "alamat": "-",
      "cp": "-",
      "telp_fax": "-",
      "perihal": "-",
      "page": 3,
      "tujuan_surat": "PT. Arga Solusi",
      "lokasi_file": "Umi",
      "file": ""
    },
    {
      "id": 9,
      "tanggal_terima": "13-01-2015",
      "penerima": "Maulana",
      "nomor_surat": "-",
      "tanggal_surat": "-",
      "nama_perusahaan": "Bank Mandiri",
      "alamat": "-",
      "cp": "-",
      "telp_fax": "-",
      "perihal": "-",
      "page": 1,
      "tujuan_surat": "PT. Sigi System",
      "lokasi_file": "Asih",
      "file": ""
    },
    {
      "id": 10,
      "tanggal_terima": "14-01-2015",
      "penerima": "Maulana",
      "nomor_surat": "-",
      "tanggal_surat": "-",
      "nama_perusahaan": "Bank Mandiri",
      "alamat": "-",
      "cp": "-",
      "telp_fax": "-",
      "perihal": "-",
      "page": 2,
      "tujuan_surat": "PT. Arga Solusi",
      "lokasi_file": "Umi",
      "file": ""
    }
  ]
}


mock.onGet('/api/incoming-letters').reply(() => {
  return [200, data.all]
})
