/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {

  reportToExcel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/trouble-ticket/export/${payload.start_date}/${payload.end_date}/excel`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchReportDaily({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/trouble-ticket/report/daily/${payload.date}`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchReportWeekly({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/trouble-ticket/report/weekly/${payload.start_date}/${payload.end_date}`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchReportMonthly({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/trouble-ticket/report/monthly/${payload.month}/${payload.year}`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchReportYearly({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/trouble-ticket/report/yearly/${payload.year}`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
