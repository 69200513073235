/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'
// import axios from 'axios';

export default {

  // Fetch
  fetchItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/todo-lists/date/${payload.date}`, {})
        .then((response) => {
          commit('SET_ITEMS', response.data.values)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  detailItem({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/todo-lists/${payload.id}/detail`, {})
        .then((response) => {
          commit('SET_ITEM', response.data.values.job)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  storeItem({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/todo-lists', payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error);
        })
    })
  },

  editItem({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/todo-lists/${payload.id}`, payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeItem({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/todo-lists/${payload.id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
