// NOTE
// Please use your own firebase details below. For more details visit: https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/development/firebaseIntegration.html


import firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAs0OlA65C2L-2cDvn3aIZcNPFCuHC154A",
  projectId: "siremin-sigi",
  messagingSenderId: "961385933899",
  appId: "1:961385933899:web:6b0cc7be8ba1cf584bf1ce",
};

try {
  firebase.initializeApp(firebaseConfig);
} catch (err) {
  // we skip the "already exists" message which is
  // not an actual error when we're hot-reloading
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error", err.stack);
  }
}

const fb = firebase;

export default fb;