// en, de, fr, pt
export default {
  id: {
    Dashboard: "Beranda",
    Letter: "Surat",
    IncomingLetter: "Surat Masuk",
    OutGoingLetter: "Surat Keluar",
    ManageLetter: "Kelola Surat",
    TypeOfLetter: "Jenis Surat",
    InvoiceCustomer: "Faktur ke Pelanggan",
    RequestDriver: "Permintaan Supir",
    ProformaInvoice: "Faktur Proforma",
    PurchaseOrderCustomer: "Pembelian dari Pelanggan",
    PurchaseOrderPrinciple: "Pembelian ke Principle",
    PurchaseToPrinciple: "Pembelian ke Principle",
    PrincipleName: "Nama Principle",
    Customer: "Pelanggan",
    Quotation: "Penawaran",
    RequestMessenger: "Permintaan Kurir",
    LetterDate: "Tanggal Surat",
    Receiver: "Penerima",
    Subject: "Perihal",
    Attachment: "Berkas",
    SeeLetter: "Lihat Surat",
    LetterCodes: "Kode Surat",
    LetterNumber: "Nomor Surat",
    Compose: "Buat",
    Date: "Tanggal",
    InvoiceNumber: "Nomor Invoice",
    InvoiceDescription: "Keterangan Penagihan",
    ProformaNumber: "Nomor Faktur Proforma",
    PODate: "Tanggal PO",
    PONumber: "Nomor PO",
    ProjectName: "Nama Proyek",
    CustomerName: "Nama Customer",
    QuotationNumber: "Nomor Quotation",
    SalesName: "Nama Sales",
    Description: "Deskripsi",
    Add: "Tambah",
    List: "Daftar",
    DateOfReceipt: "Tanggal Terima",
    LetterDetail: "Detail Surat",
    OriginOfTheLetter: "Asal Surat",
    Finish: "Selesai",
    CompanyName: "Nama Perusahaan",
    Adress: "Alamat",
    ContactPerson: "Kontak",
    PhoneFax: "Tlp / Fax",
    Page: "Halaman",
    Purpose: "Tujuan",
    To: "Ke",
    FileLocation: "Lokasi File",
    File: "File",
    Select: "Pilih",
    Detail: "Lihat Detail",
    Edit: "Ubah",
    LetterIDDetail: "Detail Surat ID",
    LetterCreator: "Pembuat Surat",
    Up: "Up",
    From: "Dari",
    NoteReferTo: "Catatan",
    DateIssued: "Tanggal Submit",
    DateDue: "Tanggal Jatuh Tempo",
    NPWPCustomerNumber: "Nomor NPWP Customer",
    NPWPNumber: "Nomor NPWP",
    POContactNumber: "Nomor PO / Kontrak",
    DONumber: "Nomor DO",
    DODate: "Tanggal DO",
    SPPNumber: "Nomor SPP",
    InvoiceDate: "Tanggal Invoice",
    ReceiptNumber: "Nomor Kwitansi",
    TaxInvoiceNumber: "Nomor Faktur Pajak",
    TaxInvoiceDate: "Tanggal Faktur Pajak",
    TotalBillValue: "Nilai Total Tagihan",
    Bill: "Tagihan",
    Tax: "Pajak",
    TaxValue: "Nilai Pajak",
    TaxExchangeRates: "Kurs Pajak",
    PaymentAccount: "Rekening Pembayaran",
    Invoice: "Invoice",
    Delete: "Hapus",
    Currency: "Mata Uang",
    Discount: "Diskon",
    SN: "SN",
    TimeOut: "Waktu Masuk",
    TimeCome: "Waktu Datang",
    PurposeReal: "Tujuan",
    Priority: "Prioritas",
    Memo: "Memo",
    Repeat: "Ulangi",
    RepeatEach: "Mengulang Setiap",
    NotifStart: "Mulai Notifikasi",
    Days: "Hari",
    EveryDay: "Setiap Hari",
    EveryMonth: "Setiap Bulan",
    EveryWeek: "Setiap Minggu",
    EveryYear: "Setiap Tahun",
    DayBeforeDate: "Hari Mulai Notif",
    DateStartNotif: "Tanggal Mulai Notif",
    InventoryTag: "Inventory Tag",
    RepeatDay: "Ulangi Setiap (Hari)",
    Title: "Judul",
    SeeAbsent: "Lihat Absensi",
    ReportAbsent: "Rekap Absensi",
    Absent: "Absensi",
    TotalInvoice: "Total Invoice",
    EnterDayRepeat: "Masukan Hari",
    ContractNumber: "Nomor Kontrak",
    Employees: "Data Pegawai",
    PlaceOfBirth: "Tempat Lahir",
    DateOfBirth: "Tanggal Lahir",
    PhoneNumber: "Nomor Telepon",
    Address: "Alamat",
    Name: "Nama",
    DetailEmployee: "Informasi Pegawai",
    ListOfFamilies: "Daftar Keluarga",
    DataOfFamilies: "Data Keluarga",
    VAT: "PPN",
    Married: "Menikah",
    NotYetMarried: "Belum Menikah",
    EmergencyContacts: "Kontak Darurat",
    Relation: "Hubungan",
    Education: "Pendidikan",
    WorkExperiences: "Pengalaman Bekerja",
    Certificates: "Sertifikasi",
    ProformaDate: "Tanggal Faktur Proforma",
    EmployeeData: "Data Pegawai",
    Reminder: "Pengingat",
    SetReminder: "Atur Ulang Pengingat",
    StartDate: "Tanggal Mulai",
    EndDate: "Tanggal Selesai",
    TimeLeave: "Waktu Keluar",
    TodoList: "Daftar Tugas",
    Save: "Simpan",
    Cancel: "Batalkan",
    AddSpesification: "Tambah Spesifikasi",
    Price: "Harga",
    Spesification: "Spesifikasi",
    Back: "Kembali",
    DateUpdate: "Tanggal Update",
    Update: "Perbarui",
    OldPassword: "Password Lama",
    NewPassword: "Password Baru",
    TryPassword: "Ulangi Password",
    Position: "Posisi",
    Phone: "Telepon",
    Contact: "Kontak",
    CustomerContact: "Kontak Pelanggan",
    PrimaryMenus: "Menu Utama",
    JobTracking: "Lacak Pekerjaan",
    Sales: "Penjualan",
    Color: "Warna",
    ChartColor: "Warna Grafik",
    PlaceofBirth: "Tempat Lahir",
    Photo: "Foto",
    DateofBirth: "Tanggal Lahir",
    PostalCode: "Kode Pos",
    City: "Kota",
    Gender: "Jenis Kelamin",
    Male: "Laki-Laki",
    Female: "Perempuan",
    Password: "Kata Sandi",
    PasswordConfirmation: "Konfirmasi Kata Sandi",
    Company: "Perusahaan",
    Division: "Divisi",
    Employee: "Karyawan",
    Day: "Hari",
    LetterMaker: "Pembuat Surat",
    NewRequestDriver: "Permintaan Baru Supir",
    NewRequestMessenger: "Permintaan Baru Kurir",
    RequestActiveDriver: "Permintaan Supir Aktif",
    RequestActiveMessenger: "Permintaan Kurir Aktif",
    AttendanceIn: "Kehadiran di bulan",
    January: "Januari",
    February: "Februari",
    March: "Maret",
    April: "April",
    May: "Mei",
    June: "Juni",
    July: "Juli",
    August: "Agustus",
    September: "September",
    October: "Oktober",
    November: "November",
    December: "Desember",
    AttendanceInYear: "Kehadiran di tahun",
    AttendanceInThisWeek: "Kehadiran di minggu ini",

    // Ticketing
    TicketingSystem: "Sistem Ticketing",
    TroubleTicket: "Trouble Ticket",
    NewTroubleTicket: "Trouble Ticket Baru",
    DataTroubleTicket: "Data Trouble Ticket",
    PriorityTroubleTicket: "Ticket Berdasarkan Prioritas",
    ProductTroubleTicket: "Ticket Berdasarkan Produk",
    Report: "Laporan",
    Daily: "Harian",
    Weekly: "Mingguan",
    Monthly: "Bulanan",
    Yearly: "Tahunan",
    DeadlineTicket: "Batas Waktu Ticket",
    Urgent: "Mendesak",
    Major: "Utama",
    Minor: "Minor",
    Question: "Pertanyaan",
    Configuration: "Konfigurasi",
    ChartColors: "Warna Grafik",
  },
  en: {
    Dashboard: "Dashboard",
    eCommerce: "eCommerce",
    Apps: "Apps",
    Todo: "Todo",
    Chat: "Chat",
    Email: "Email",
    Calendar: "Calendar",
    FullCalendar: "Full Calendar",
    SimpleCalendar: "Simple Calendar",
    Shop: "Shop",
    ItemDetails: "Item Details",
    Cart: "Cart",
    WishList: "Wish List",
    Checkout: "Checkout",
    UI: "UI",
    DataList: "Data List",
    ListView: "List View",
    ThumbView: "Thumb View",
    Grid: "Grid",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Colors",
    Card: "Card",
    Basic: "Basic",
    Statistics: "Statistics",
    Analytics: "Analytics",
    CardActions: "Card Actions",
    CardColors: "Card Colors",
    Table: "Table",
    agGridTable: "agGrid Table",
    Components: "Components",
    Alert: "Alert",
    Avatar: "Avatar",
    Badge: "Badge",
    Breadcrumb: "Breadcrumb",
    Button: "Button",
    ButtonGroup: "Button Group",
    Chip: "Chip",
    Collapse: "Collapse",
    Dialogs: "Dialogs",
    Divider: "Divider",
    DropDown: "DropDown",
    List: "List",
    Loading: "Loading",
    Navbar: "Navbar",
    Notifications: "Notifications",
    Pagination: "Pagination",
    Popup: "Popup",
    Progress: "Progress",
    Sidebar: "Sidebar",
    Slider: "Slider",
    Tabs: "Tabs",
    Tooltip: "Tooltip",
    Upload: "Upload",
    FormsAndTable: "Forms & Table",
    FormElements: "Form Elements",
    Switch: "Switch",
    Checkbox: "Checkbox",
    Radio: "Radio",
    Input: "Input",
    NumberInput: "Number Input",
    Textarea: "Textarea",
    FormLayouts: "Form Layouts",
    FormWizard: "Form Wizard",
    FormValidation: "Form Validation",
    FormInputGroup: "Form Input Group",
    Pages: "Pages",
    Authentication: "Authentication",
    Login: "Login",
    Register: "Register",
    ForgotPassword: "Forgot Password",
    ResetPassword: "Reset Password",
    LockScreen: "Lock Screen",
    Miscellaneous: "Miscellaneous",
    ComingSoon: "Coming Soon",
    Error: "Error",
    404: "404",
    500: "500",
    NotAuthorized: "Not Authorized",
    Maintenance: "Maintenance",
    Profile: "Profile",
    User: "User",
    View: "View",
    UserSettings: "User Settings",
    Invoice: "Invoice",
    FAQ: "FAQ",
    Search: "Search",
    KnowledgeBase: "Knowledge Base",
    ChartsAndMaps: "Charts & Maps",
    Charts: "Charts",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Map",
    Extensions: "Extensions",
    QuillEditor: "Quill Editor",
    DragAndDrop: "Drag & Drop",
    Datepicker: "Datepicker",
    DatetimePicker: "Datetime Picker",
    AccessControl: "Access Control",
    I18n: "I18n",
    Carousel: "Carousel",
    Clipboard: "Clipboard",
    ContextMenu: "Context Menu",
    StarRatings: "Star Ratings",
    Autocomplete: "Autocomplete",
    Tree: "Tree",
    // "Import/Export": 'Import/Export',
    Import: "Import",
    Export: "Export",
    ExportSelected: "Export Selected",
    Others: "Others",
    MenuLevels: "Menu Levels",
    MenuLevel2p1: "Menu Level 2.1",
    MenuLevel2p2: "Menu Level 2.2",
    MenuLevel3p1: "Menu Level 3.1",
    MenuLevel3p2: "Menu Level 3.2",
    DisabledMenu: "Disabled Menu",
    Support: "Support",
    Documentation: "Documentation",
    RaiseSupport: "Raise Support",
    demoTitle: "Card Title",
    demoText:
      "Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.",
    Letter: "Letter",
    IncomingLetter: "Incoming Letter",
    OutGoingLetter: "Outgoing Letter",
    ManageLetter: "Manage Letter",
    TypeOfLetter: "Type of Letter",
    InvoiceCustomer: "Invoice to Customer",
    RequestDriver: "Request Driver",
    ProformaInvoice: "Proforma Invoice",
    PurchaseOrderCustomer: "PO from Customer",
    PurchaseOrderPrinciple: "PO to Principle",
    PurchaseToPrinciple: "PO to Principle",
    PrincipleName: "Principle Name",
    Customer: "Customer",
    Quotation: "Quotation",
    RequestMessenger: "Request Messenger",
    LetterDate: "Letter Date",
    Receiver: "Receiver",
    Subject: "Subject",
    Attachment: "Attachment",
    SeeLetter: "List Letter",
    LetterCodes: "Letter Codes",
    LetterNumber: "Letter Number",
    Compose: "Compose",
    Date: "Date",
    InvoiceNumber: "Invoice Number",
    InvoiceDescription: "Invoice Description",
    ProformaNumber: "Proforma Invoice Number",
    PODate: "PO Date",
    PONumber: "PO Number",
    ProjectName: "Project Name",
    CustomerName: "Customer Name",
    QuotationNumber: "Quotation Number",
    SalesName: "Sales Name",
    Description: "Description",
    DateOfReceipt: "Date Of Receipt",
    LetterDetail: "Letter Detail",
    OriginOfTheLetter: "Origin of The Letter",
    Finish: "Finish",
    CompanyName: "Company Name",
    Adress: "Adress",
    ContactPerson: "Contact Person",
    PhoneFax: "Phone / Fax",
    PhoneNumber: "Phone Number",
    Page: "Page",
    Purpose: "The Purpose of The Letter",
    FileLocation: "File Location",
    File: "File",
    Select: "Select",
    Detail: "More Detail",
    Edit: "Edit",
    LetterIDDetail: "Letter ID Detail",
    LetterCreator: "Letter Creator",
    Up: "Up",
    From: "From",
    NoteReferTo: "Note / Refer To",
    DateIssued: "Date Issued",
    DateDue: "Date Due",
    NPWPCustomerNumber: "NPWP Customer Number",
    POContactNumber: "PO Contract Number",
    DONumber: "DO Number",
    DODate: "DO Date",
    SPPNumber: "SPP Number",
    InvoiceDate: "Invoice Date",
    ReceiptNumber: "Receipt Number",
    TaxInvoiceNumber: "Tax Invoice Number",
    TaxInvoiceDate: "Tax Invoice Date",
    TotalBillValue: "Total Bill Value",
    Tax: "Tax",
    TaxValue: "Tax Value",
    TaxExchangeRates: "Tax Exchange Rates",
    PaymentAaccount: "Payment Account",
    Delete: "Delete",
    Currency: "Currency",
    Discount: "Discount",
    SN: "SN",
    TimeOut: "Time Out",
    TimeCome: "Time Come",
    PurposeReal: "Purpose",
    To: "To",
    Priority: "Priority",
    Memo: "Memo",
    NotifStart: "Notif Start",
    Repeat: "Repeat",
    RepeatEach: "Repeat Each",
    Days: "Days",
    EveryDay: "Every Day",
    EveryMonth: "Every Month",
    EveryWeek: "Every Week",
    EveryYear: "Every Year",
    RepeatDay: "Repeat Day",
    Title: "Title",
    DateStartNotif: "Date Start Notif",
    PaymentAccount: "Payment Account",
    NPWPNumber: "NPWP Number",
    SeeAbsent: "See Absent",
    TotalInvoiceValue: "Total Invoice",
    ReportAbsent: "Report Absent",
    Absent: "Absent",
    TotalInvoice: "Total Invoice",
    EnterDayRepeat: "Enter Day Repeat",
    ContractNumber: "Contract Number",
    Employees: "Employees",
    PlaceOfBirth: "Place of Birth",
    DateOfBirth: "Date of Birth",
    Address: "Address",
    Add: "Add",
    Name: "Name",
    DetailEmployee: "Detail Employee",
    ListOfFamilies: "List of Families",
    DataOfFamilies: "Data of Families",
    VAT: "VAT",
    Married: "Married",
    NotYetMarried: "Not Yet Married",
    EmergencyContacts: "Emergency Contacts",
    Relation: "Relation",
    Education: "Education",
    WorkExperiences: "WorkExperiences",
    Certificates: "Certificates",
    ProformaDate: "Proforma Invoice Date",
    EmployeeData: "Employees",
    SetReminder: "Set Reminder",
    StartDate: "Start Date",
    EndDate: "End Date",
    TimeLeave: "Time Leave",
    Technical: "Technical",
    Equipment: "Equipment",
    MonthlyReport: "Monthly Report",
    TechTeamReport: "Tech Team Report",
    LearningCenter: "Learning Center",
    KnowledgeBase: "Knowledge Base",
    IssueTracking: "Issue Tracking",
    HelpdeskSchedule: "Helpdesk Schedule",
    Sales: "Sales",
    Forecast: "Forecast",
    CustomerContact: "Customer Contact",
    Report: "Report",
    VisitReport: "Visit Report",
    MomReport: "Mom Report",
    ProgressReport: "Progress Report",
    SalesPO: "Sales PO",
    FromForecast: "From Forecast",
    NotFromForecast: "Not From Forecast",
    TodoList: "To do List",
    ViewReport: "View Report",
    JobTracking: "Job Tracking",
    PurchaseOrder: "Purchase Order",
    AddSpesification: "Add Spesification",
    DateUpdate: "Date Update",
    OldPassword: "Old Password",
    NewPassword: "New Password",
    TryPassword: "Try Password",
    PrimaryMenus: "Primary Menu",
    Color: "Color",
    ChartColor: "Chart Color",
    PlaceofBirth: "Place of Birth",
    DateofBirth: "Date of Birth",
    PostalCode: "Postal Code",
    PasswordConfirmation: "Password Confirmation",
    LetterMaker: "Letter Maker",
    NewRequestDriver: "New Request Driver",
    NewRequestMessenger: "New Request Messenger",
    RequestActiveDriver: "Request Active Driver",
    RequestActiveMessenger: "Request Active Messenger",
    AttendanceIn: "Attendance in",
    AttendanceInYear: "Attendance in Year",
    AttendanceInThisWeek: "Attendance in this week",
    ContactName: "Contact Name",
    MeetingDate: "Meeting Date",
    CreatedAt: "Created At",

    // Ticketing
    TicketingSystem: "Ticketing System",
    TroubleTicket: "Trouble Ticket",
    NewTroubleTicket: "New Trouble Ticket",
    DataTroubleTicket: "Data Trouble Ticket",
    PriorityTroubleTicket: "Ticket By Priority",
    ProductTroubleTicket: "Ticket By Product",
    Report: "Report",
    Daily: "Daily",
    Weekly: "Weekly",
    Monthly: "Monthly",
    Yearly: "Yearly",
    DeadlineTicket: "Deadline Ticket",
    Urgent: "Urgent",
    Major: "Major",
    Minor: "Minor",
    Question: "Question",
    Configuration: "Configuration",
    ChartColors: "Colors of Chart",

    // Sparepart CLU
    CLUIn: "CLU In",
    CLUOut: "CLU Out",
    AddCLU: "Add CLU",
    EditCLU: "Edit CLU",

    // Sparepart Handphone
    HandphoneIn: "Handphone In",
    HandphoneOut: "Handphone Out",
    AddHandphone: "Add Handphone",
    EditHandphone: "Edit Handphone",

    // Sparepart HDD
    HDDIn: "HDD In",
    HDDOut: "HDD Out",
    AddHDD: "Add HDD",
    EditHDD: "Edit HDD",

    // Sparepart Interface
    InterfaceIn: "Interface In",
    InterfaceOut: "Interface Out",
    AddInterface: "Add Interface",
    EditInterface: "Edit Interface",

    // Sparepart PowerSupply
    PowerSupply: "Power Supply",
    PowerSupplyIn: "Power Supply In",
    PowerSupplyOut: "Power Supply Out",
    AddPowerSupply: "Add Power Supply",
    EditPowerSupply: "Edit Power Supply",

    // Sparepart Simboard
    SimboardIn: "Simboard In",
    SimboardOut: "Simboard Out",
    AddSimboard: "Add Simboard",
    EditSimboard: "Edit Simboard",
  },
  de: {
    Dashboard: "Instrumententafel",
    Letter: "Brief",
    IncomingLetter: "Eingehender Brief",
    OutGoingLetter: "Ausgehende e-Mail",
    ManageLetter: "Kelola Surat",
    TypeOfLetter: "Mail verwalten",
    InvoiceCustomer: "Kundenrechnung",
    RequestDriver: "Fragen Sie den Fahrer",
    ProformaInvoice: "Proforma-Rechnung",
    PurchaseOrderCustomer: "Kundenkäufe",
    Customer: "Kunde",
    Quotation: "Zitat",
    RequestMessenger: "Fragen Sie nach dem Durcheinander",
    LetterDate: "Letter Date",
    Receiver: "Receiver",
    Subject: "Subject",
    Attachment: "Attachment",
    SeeLetter: "List Letter",
    LetterCodes: "Letter Codes",
    LetterNumber: "Letter Number",
    Compose: "Compose",
    Date: "Date",
    InvoiceNumber: "Invoice Number",
    InvoiceDescription: "Invoice Description",
    ProformaNumber: "Proforma Invoice Number",
    PODate: "PO Date",
    PONumber: "PO Number",
    ProformaDate: "Proforma Invoice Date",
    ProjectName: "Project Name",
    CustomerName: "Customer Name",
    QuotationNumber: "Quotation Number",
    SalesName: "Sales Name",
    Description: "Description",
    DateOfReceipt: "Date Of Receipt",
    LetterDetail: "Letter Detail",
    OriginOfTheLetter: "Origin of The Letter",
    Finish: "Finish",
    CompanyName: "Company Name",
    Adress: "Adress",
    ContactPerson: "Contact Person",
    PhoneFax: "Phone / Fax",
    PhoneNumber: "Phone Number",
    Page: "Page",
    Purpose: "The Purpose of The Letter",
    FileLocation: "File Location",
    File: "File",
    Select: "Select",
    Detail: "More Detail",
    Edit: "Edit",
    LetterIDDetail: "Letter ID Detail",
    LetterCreator: "Letter Creator",
    Up: "Up",
    From: "From",
    NoteReferTo: "Note / Refer To",
    DateIssued: "Date Issued",
    DateDue: "Date Due",
    NPWPCustomerNumber: "NPWP Customer Number",
    NPWPNumber: "NPWP Number",
    POContactNumber: "PO Contact Number",
    DONumber: "DO Number",
    DODate: "DO Date",
    SPPNumber: "SPP Number",
    InvoiceDate: "Invoice Date",
    ReceiptNumber: "Receipt Number",
    TaxInvoiceNumber: "Tax Invoice Number",
    TaxInvoiceDate: "Tax Invoice Date",
    TotalBillValue: "Total Bill Value",
    Tax: "Tax",
    TaxValue: "Tax Value",
    TaxExchangeRates: "Tax Exchange Rates",
    Delete: "Delete",
    Currency: "Currency",
    DayBeforeDate: "Days Before Date (Notification)",
    InventoryTag: "Inventory Tag",
    RepeatDay: "Ulangi Setiap Tanggal",
    PrimaryMenus: "Hauptmenüs",
    JobTracking: "Auftragsverfolgung",
    Sales: "Der Umsatz",
    TodoList: "Aufgabenliste",
    EmployeeData: "Mitarbeiterdaten",
    Absent: "Abwesend",
    SeeAbsent: "Siehe Abwesend",
    ReportAbsent: "Abwesend melden",
    Reminder: "Erinnerung",
    Color: "Farbe",
    ChartColor: "Diagrammfarbe",
    NewRequestDriver: "Neuer Anfragetreiber",
    NewRequestMessenger: "Neuer Anfrage Messenger",
    RequestActiveDriver: "Aktiven Treiber Anfordern",
    RequestActiveMessenger: "Aktiven Messenger Anfordern",
    AttendanceIn: "Teilnahme in",
    January: "Januar",
    February: "Februar",
    March: "März",
    April: "April",
    May: "Kann",
    June: "Juni",
    July: "Juli",
    August: "August",
    September: "September",
    October: "Oktober",
    November: "November",
    December: "Dezember",
    AttendanceInYear: "Teilnahme im Jahr",
    AttendanceInThisWeek: "Teilnahme in dieser Woche",
  },
  fr: {
    Dashboard: "Tableau de bord",
    eCommerce: "commerce électronique",
    Apps: "Applications",
    Todo: "Faire",
    Chat: "Bavarder",
    Email: "Email",
    Calendar: "Calendrier",
    FullCalendar: "Calendrier complet",
    SimpleCalendar: "Calendrier simple",
    Shop: "Boutique",
    ItemDetails: "Détails de l'article",
    Cart: "Chariot",
    WishList: "Liste de souhaits",
    Checkout: "Check-out",
    UI: "UI",
    DataList: "Liste de données",
    ListView: "Voir la liste",
    ThumbView: "Thumb View",
    Grid: "la grille",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Couleurs",
    Card: "Carte",
    Basic: "De base",
    Statistics: "Statistiques",
    Analytics: "Analytique",
    CardActions: "Actions de carte",
    CardColors: "Couleurs de la carte",
    Table: "Table",
    agGridTable: "tableau agGrid",
    Components: "Composants",
    Alert: "Alerte",
    Avatar: "Avatar",
    Badge: "Badge",
    Breadcrumb: "Breadcrumb",
    Button: "Bouton",
    ButtonGroup: "Groupe de boutons",
    Chip: "Puce",
    Collapse: "Effondrer",
    Dialogs: "Dialogues",
    Divider: "Diviseur",
    DropDown: "Menu déroulant",
    List: "liste",
    Loading: "Chargement",
    Navbar: "Barre de navigation",
    Notifications: "Les notifications",
    Pagination: "Pagination",
    Popup: "Apparaitre",
    Progress: "Le progrès",
    Sidebar: "Barre latérale",
    Slider: "Curseur",
    Tabs: "Onglets",
    Tooltip: "Info-bulle",
    Upload: "Télécharger",
    FormsAndTable: "Formulaires et tableau",
    FormElements: "Éléments de formulaire",
    Select: "Sélectionner",
    Switch: "Commutateur",
    Checkbox: "Case à cocher",
    Radio: "Radio",
    Input: "Contribution",
    NumberInput: "Nombre d'entrée",
    Textarea: "Textarea",
    FormLayouts: "Dispositions de formulaire",
    FormWizard: "Assistant de formulaire",
    FormValidation: "Validation du formulaire",
    FormInputGroup: "Groupe de saisie de formulaire",
    Pages: "Pages",
    Authentication: "Authentification",
    Login: "S'identifier",
    Register: "registre",
    ForgotPassword: "Mot de passe oublié",
    ResetPassword: "réinitialiser le mot de passe",
    LockScreen: "Écran verrouillé",
    Miscellaneous: "Divers",
    ComingSoon: "Arrive bientôt",
    Error: "Erreur",
    404: "404",
    500: "500",
    NotAuthorized: "Pas autorisé",
    Maintenance: "Entretien",
    Profile: "Profil",
    User: "Utilisateur",
    View: "Vue",
    Edit: "Modifier",
    UserSettings: "Paramètres utilisateur",
    Invoice: "Facture d'achat",
    FAQ: "FAQ",
    Search: "Chercher",
    KnowledgeBase: "Base de connaissances",
    ChartsAndMaps: "Graphiques Et Cartes",
    Charts: "Graphiques",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Carte",
    Extensions: "Extensions",
    QuillEditor: "Éditeur de piquants",
    DragAndDrop: "Drag & Drop",
    Datepicker: "Sélecteur de date",
    DatetimePicker: "Sélecteur de date / heure",
    AccessControl: "Contrôle d'accès",
    I18n: "I18n",
    Carousel: "Carrousel",
    Clipboard: "Presse-papiers",
    ContextMenu: "Menu contextuel",
    StarRatings: "Classement par étoiles",
    Autocomplete: "Terminé automatiquement",
    Tree: "Arbre",
    // "Import/Export": 'Importation/Exportation',
    Import: "Importation",
    Export: "Exportation",
    ExportSelected: "Exporter sélectionné",
    Others: "Autre",
    MenuLevels: "Niveaux de menu",
    MenuLevel2p1: "Niveau de menu 2.1",
    MenuLevel2p2: "Niveau de menu 2.2",
    MenuLevel3p1: "Niveau de menu 3.1",
    MenuLevel3p2: "Niveau de menu 3.2",
    DisabledMenu: "Menu désactivé",
    Support: "Soutien",
    Documentation: "Documentation",
    RaiseSupport: "Augmenter le soutien",
    demoTitle: "Titre de la carte",
    demoText:
      "Le gâteau au sésame s'enclenche au petit pain au pain d'épices danois J'adore le pain d'épices. Tarte aux pommes et jujubes chupa chups muffin halvah sucette. Tiramisu gâteau au chocolat gâteau d'avoine tiramisu prune sucre. Donut tarte sucrée gâteau à l'avoine dragée gouttes de fruits gâteau au citron en barbe à papa.",
  },
  pt: {
    Dashboard: "painel de controle",
    eCommerce: "comércio eletrônico",
    Apps: "APPS",
    Todo: "Façam",
    Chat: "Bate-papo",
    Email: "O email",
    Calendar: "Calendário",
    FullCalendar: "Calendário Completo",
    SimpleCalendar: "Calendário Simples",
    Shop: "fazer compras",
    ItemDetails: "Detalhes do item",
    Cart: "Carrinho",
    WishList: "Lista de Desejos",
    Checkout: "Confira",
    UI: "UI",
    DataList: "Lista de dados",
    ListView: "Exibição de lista",
    ThumbView: "Thumb View",
    Grid: "Grade",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Cores",
    Card: "Cartão",
    Basic: "Basic",
    Statistics: "Estatisticas",
    Analytics: "Analytics",
    CardActions: "Ações do Cartão",
    CardColors: "Cores do cartão",
    Table: "Mesa",
    agGridTable: "Tabela AgGrid",
    Components: "Componentes",
    Alert: "Alerta",
    Avatar: "Avatar",
    Badge: "Crachá",
    Breadcrumb: "Breadcrumb",
    Button: "Botão",
    ButtonGroup: "Grupo de botões",
    Chip: "Lasca",
    Collapse: "Colapso",
    Dialogs: "Diálogos",
    Divider: "Divisor",
    DropDown: "Suspenso",
    List: "Lista",
    Loading: "Carregando",
    Navbar: "Navbar",
    Notifications: "Notificações",
    Pagination: "Paginação",
    Popup: "Aparecer",
    Progress: "Progresso",
    Sidebar: "Barra Lateral",
    Slider: "Controle Deslizante",
    Tabs: "Guias",
    Tooltip: "dica de ferramenta",
    Upload: "Envio",
    FormsAndTable: "Formulários e tabela",
    FormElements: "Elementos do formulário",
    Select: "Selecione",
    Switch: "Interruptor",
    Checkbox: "Caixa de seleção",
    Radio: "Rádio",
    Input: "Entrada",
    NumberInput: "Entrada numérica",
    Textarea: "Textarea",
    FormLayouts: "Layouts de formulário",
    FormWizard: "Assistente de Formulário",
    FormValidation: "Validação de Formulário",
    FormInputGroup: "Grupo de entrada de formulário",
    Pages: "PÁGINAS",
    Authentication: "Autenticação",
    Login: "Entrar",
    Register: "Registo",
    ForgotPassword: "Esqueceu A Senha",
    ResetPassword: "Redefinir Senha",
    LockScreen: "Tela de bloqueio",
    Miscellaneous: "Diversos",
    ComingSoon: "Em breve",
    Error: "Erro",
    404: "404",
    500: "500",
    NotAuthorized: "Não autorizado",
    Maintenance: "Manutenção",
    Profile: "Perfil",
    User: "Do utilizador",
    View: "Visão",
    Edit: "Editar",
    UserSettings: "Configurações do Usuário",
    Invoice: "Fatura",
    FAQ: "Perguntas frequentes",
    Search: "Procurar",
    KnowledgeBase: "Base de Conhecimento",
    ChartsAndMaps: "Gráficos E Mapas",
    Charts: "Gráficos",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Mapa",
    Extensions: "Extensão",
    QuillEditor: "Quill-Editor",
    DragAndDrop: "Arraste e solte",
    Datepicker: "Datepicker",
    DatetimePicker: "Seletor de data e hora",
    AccessControl: "Controle de acesso",
    I18n: "I18n",
    Carousel: "Carrossel",
    Clipboard: "Prancheta",
    ContextMenu: "Menu contextual",
    StarRatings: "Classificações por estrelas",
    Autocomplete: "autocompletar",
    Tree: "Árvore",
    // "Import/Export": 'Importar/Exportar',
    Import: "Importar",
    Export: "Exportar",
    ExportSelected: "Exportar Selecionado",
    Others: "Outras",
    MenuLevels: "Níveis de Menu",
    MenuLevel2p1: "Nível de menu 2.1",
    MenuLevel2p2: "Nível de menu 2.2",
    MenuLevel3p1: "Nível de menu 3.1",
    MenuLevel3p2: "Nível de menu 3.2",
    DisabledMenu: "Menu desativado",
    Support: "Apoio, suporte",
    Documentation: "Documentação",
    RaiseSupport: "Levantar Suporte",
    demoTitle: "Título do cartão",
    demoText:
      "O sésamo do bolo agarra dinamarquês do pão-de-espécie do queque eu amo o pão-de-espécie. Torta de torta de maçã jujuba chupa chups muffin halvah pirulito. Ameixa do açúcar do maçapão do tiramisu do bolo da aveia do bolo de chocolate. Bolo de aveia de torta doce rosquinha dragée fruitcake algodão doce gotas de limão.",
  },
};
