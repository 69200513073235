/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  // fetchAllItem ({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios.get(`/technical/learning-center/learning-theory`)
  //       .then((response) => {
  //         commit('SET_ITEMS', response.data.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },

  fetchKnowledgeBase ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/technical/learning-center/learning-category`)
        .then((response) => {
          commit('SET_ITEMS', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  
  fetchIssueTracking({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/technical/learning-center/learning-theory/?for_type=2`)
        .then((response) => {
          commit('SET_ITEMS', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  detailItem({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/technical/learning-center/learning-theory/${payload.id}`)
        .then((response) => {
          commit('SET_ITEM', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  storeItem(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/technical/learning-center/learning-theory`, payload.body)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },

  updateItem(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/technical/learning-center/learning-theory/${payload.id}`, payload.body)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },

  addComment({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/technical/learning-center/learning-theory/${payload.id}/activity`, payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  destroyItem ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/technical/learning-center/learning-theory/${payload}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => { reject(error) })
    })
  }
}
