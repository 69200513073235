/*=========================================================================================
  File Name: moduleEmailMutations.js
  Description: Email Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_ITEMS (state, items) {
    state.items = items
  },
  SET_ITEM (state, item) {
    state.item = item
  },
  SET_HISTORIES (state, histories) {
    state.histories = histories;
  }
}
