/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

// import moduleTodo from './todo/moduleTodo.js'
// import moduleCalendar from './calendar/moduleCalendar.js'
// import moduleChat from './chat/moduleChat.js'
// import moduleEmail from './email/moduleEmail.js'
import moduleAuth from './auth/moduleAuth.js'
import moduleECommerce from './eCommerce/moduleECommerce.js'
import moduleIncomingLetter from "@/store/apps/incoming-letter/moduleIncomingLetter";
import moduleOutgoingLetter from "@/store/apps/outgoing-letter/moduleOutgoingLetter";
import moduleInvoiceCustomer from "@/store/apps/invoice-customer/moduleInvoiceCustomer";
import moduleProformaInvoice from "@/store/apps/proforma-invoice/moduleProformaInvoice";
import modulePurchase from "@/store/apps/purchase/modulePurchase";
import moduleQuotation from "@/store/apps/quotation/moduleQuotation";
import moduleLetterCode from '@/store/apps/letter-code/moduleLetterCode'
import moduleRequest from '@/store/apps/request/moduleRequest'
import moduleReminder from "@/store/apps/reminder/moduleReminder";
import moduleInventory from "@/store/apps/inventory/moduleInventory";
import moduleEmployee from "@/store/apps/employee/moduleEmployee";
import moduleJob from "@/store/apps/job/moduleJob";
import moduleTodoList from "@/store/apps/todolist/moduleTodoList";
import moduleViewReport from "@/store/apps/viewreport/moduleViewReport";
import moduleLearningCenter from "@/store/learning-center/moduleLearningCenter";

import moduleDashboard from "@/store/ticketing/dashboard/moduleDashboard"
import moduleReport from "@/store/ticketing/report/moduleReport"
import moduleTicket from "@/store/ticketing/trouble-ticket/moduleTicket"
import moduleProduct from "@/store/ticketing/product/moduleProduct"
import moduleCustomer from "@/store/ticketing/customer/moduleCustomer"

import moduleSparepart from "@/store/sparepart/moduleSparepart";
import moduleCategory from "@/store/sparepart/category/moduleCategory";
import moduleBrand from "@/store/sparepart/brand/moduleBrand";

import moduleEquipment from "@/store/equipment/moduleEquipment";
import moduleBrandEquipment from "@/store/equipment/brand/moduleBrandEquipment";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    // todo: moduleTodo,
    // calendar: moduleCalendar,
    // chat: moduleChat,
    // email: moduleEmail,
    auth: moduleAuth,
    eCommerce: moduleECommerce,
    incomingLetter: moduleIncomingLetter,
    outgoingLetter: moduleOutgoingLetter,
    invoiceCustomer: moduleInvoiceCustomer,
    proformaInvoice: moduleProformaInvoice,
    purchase: modulePurchase,
    quotation: moduleQuotation,
    letterCode: moduleLetterCode,
    request: moduleRequest,
    reminder: moduleReminder,
    inventory: moduleInventory,
    employee: moduleEmployee,
    job: moduleJob,
    todolist: moduleTodoList,
    viewreport: moduleViewReport,
    learningCenter: moduleLearningCenter,

    dashboard: moduleDashboard,
    report: moduleReport,
    ticket: moduleTicket,
    product: moduleProduct,
    customer: moduleCustomer,

    // technical - sparepart
    sparepart: moduleSparepart,
    sparepartCategory: moduleCategory,
    sparepartBrand: moduleBrand,

    // technical - equipment
    equipment: moduleEquipment,
    equipmentBrand: moduleBrandEquipment,
  },
  strict: process.env.NODE_ENV !== "production",
});
