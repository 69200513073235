/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
import moment from 'moment';

Vue.prototype.$server = baseURL;
// Vue.prototype.$server = 'https://api.e-office.sigisystems.com';
Vue.prototype.$storageEmployee = Vue.prototype.$server + "/upload/employees";
Vue.prototype.$storageUser = Vue.prototype.$server + "/upload/users";

Vue.prototype.$moment = (event) => {
  return moment(event).format("YYYY-MM-DD");
};

Vue.prototype.$convertDate = (event, format) => {
  return moment(event).format(format);
};

Vue.prototype.$toCurrency = (value) => {
  return currency(value, { separator: ".", precision: 0, symbol: "" }).format();
};

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// Vue Select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

// Sweetalert
import VueSweetalert2 from "vue-sweetalert2";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};
Vue.use(VueSweetalert2, options);

// Crypto
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);
Vue.prototype.$secure = {
  encrypt(message) {
    return Vue.CryptoJS.AES.encrypt(message.toString(), "#tahubulat")
      .toString()
      .replace("/", "+AiM");
  },
  decrypt(encrypted) {
    return Vue.CryptoJS.AES.decrypt(
      encrypted.replace("+AiM", "/"),
      "#tahubulat"
    ).toString(Vue.CryptoJS.enc.Utf8);
  },
};

import FileUpload from "v-file-upload";

Vue.use(FileUpload);

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// API Calls
import "./http/requests";

// mock
import "./fake-db/index.js";

// Theme Configurations
import "../themeConfig.js";

// Firebase
// import '@/firebase/firebaseConfig'
import fb from "@/firebase/firebaseConfig";
import "firebase/messaging";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service worker registered: ", registration);
    })
    .catch((err) => {
      console.log("Service worker registration failed: ", err);
    });
}

const messaging = fb.messaging();

messaging.onMessage((payload) => {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      registration.showNotification(
        payload.notification.title,
        payload.notification
      );
    });
});

// Auth0 Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);

// ACL
import acl from "./acl/acl";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// i18n
import i18n from "./i18n/i18n";

// Vuexy Admin Filters
import "./filters/filters";

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Tour
import VueTour from "vue-tour";
Vue.use(VueTour);
require("vue-tour/dist/vue-tour.css");

// VeeValidate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
Vue.prototype.$setErrorsFromResponse = function (errorResponse) {
  // only allow this function to be run if the validator exists
  if (!this.hasOwnProperty("$validator")) {
    return;
  }

  // clear errors
  this.$validator.errors.clear();

  let errorFields = Object.keys(errorResponse);

  // insert laravel errors
  errorFields.map((field) => {
    let errorString = errorResponse[field].join(", ");
    this.$validator.errors.add({
      field: field,
      msg: errorString,
    });
  });
};

// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: "AIzaSyB4DDathvvwuwlwnUu7F4Sow3oU22y5T1Y",
    libraries: "places", // This is required if you use the Auto complete plug-in
  },
});

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import currency from "currency.js";

// Vue currency input
import VueCurrencyInput from "vue-currency-input";

const pluginOptions = {
  /* see config reference */
  globalOptions: {
    currency: null,
    locale: "id",
    precision: 0,
    distractionFree: {
      hideNegligibleDecimalDigits: true,
      hideCurrencySymbol: true,
    },
  },
};
Vue.use(VueCurrencyInput, pluginOptions);

// Vue Marquee
import MarqueeText from "vue-marquee-text-component";
Vue.component("marquee-text", MarqueeText);

// Vue Particles
import Particles from "particles.vue";
Vue.use(Particles);

// Vue Typed JS
import VueTypedJs from "vue-typed-js";
import baseURL from "./recipe";
Vue.use(VueTypedJs)

// Feather font icon
require('./assets/css/iconfont.css')

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false

// Pusher
var Pusher = require('pusher-js');
Pusher.logToConsole = true;


new Vue({
  router,
  store,
  i18n,
  acl,
  ready: function () {
    this.pusher = new Pusher('54da8dbe1843b5ca971b', {
      cluster: 'ap1'
    });
    this.pusherChannel = this.pusher.subscribe('my-channel');
    this.pusherChannel.bind('my-event', function (data) {
      console.log(data);
    });
  },
  render: h => h(App)
}).$mount('#app')
