import mock from '@/fake-db/mock.js'

const data = {
  all: [
    {
      "id": 1,
      "tanggal": "05-01-2015",
      "ket_penagihan": "Pelunasan 50% : VCL - 16E1 + Ethernet PDH Optical Multiplexer connector SC",
      "date_issued": "06-01-2015",
      "date_due": "08-01-2015",
      "customer": "CV. BERKAH INDO MAKMUR ABADI",
      "no_npwp_customer": "31.723.227.0-422.000",
      "no_po_kontrak": "BERKAH_179/PO/XII/14",
      "do_number": "-",
      "do_date": "-",
      "spp_number": "005/AD-AST/I/2015",
      "invoice_number": "006/IV-AST/I/2015",
      "invoice_date": "05-01-2015",
      "kwitansi_number": "007/KW-AST/I/2015",
      "no_faktur_pajak": "010.000.15.62906387",
      "tgl_faktur_pajak": "05-01-2015",
      "matauang_total_tagihan": 1,
      "nilai_total_tagihan": "1910.7",
      "matauang_invoice": 1,
      "invoice": "1737",
      "matauang_pajak": 1,
      "pajak": "173.7",
      "nilai_pajak": "2161002",
      "kurs_pajak": "12441",
      "keterangan": " Pengiriman Invoice via E-mail, dikirim pada tanggal 06 Januari 2015 (melalui Mba Teti) ",
      "rekening_pembayaran": " BRI ",
      "file": "invoice_2015_1.pdf"
    },
    {
      "id": 2,
      "tanggal": "07-01-2015",
      "ket_penagihan": "Payment 2 - Pengadaan Jasa Deteksi SIMBOX Kategori Non VoIP (Periode 15 Nov 2014 – 14 Des 2014)",
      "date_issued": "07-01-2015",
      "date_due": "27-03-2015",
      "customer": "PT. Telekomunikasi Selular (Telkomsel)",
      "no_npwp_customer": "01.718.327.8-093.000",
      "no_po_kontrak": "PO No.: eHOP141084",
      "do_number": "-",
      "do_date": "-",
      "spp_number": "011/AD-AST/I/2015",
      "invoice_number": "012/IV-AST/I/2015",
      "invoice_date": "07-01-2015",
      "kwitansi_number": "013/KW-AST/I/2015",
      "no_faktur_pajak": "010.000.15.62906388",
      "tgl_faktur_pajak": "07-01-2015",
      "matauang_total_tagihan": 3,
      "nilai_total_tagihan": "181500000",
      "matauang_invoice": 3,
      "invoice": "165000000",
      "matauang_pajak": 3,
      "pajak": "16500000",
      "nilai_pajak": "0",
      "kurs_pajak": "0",
      "keterangan": " Invoice diterima tanggal 07 Januari 2015, dan diterima oleh Ibu Yona ",
      "rekening_pembayaran": " Bank Mandiri ",
      "file": "invoice_2015_2.pdf"
    },
    {
      "id": 3,
      "tanggal": "21-01-2015",
      "ket_penagihan": "Payment 3 - Pengadaan Jasa Deteksi SIMBOX Kategori Non VoIP (Periode 15 Des 2014 – 14 Jan 2015)",
      "date_issued": "21-01-2015",
      "date_due": "27-03-2015",
      "customer": "PT. Telekomunikasi Selular (Telkomsel)",
      "no_npwp_customer": "01.718.327.8-093.000",
      "no_po_kontrak": "PO No.: eHOP141084",
      "do_number": "-",
      "do_date": "-",
      "spp_number": "022/AD-AST/I/2015",
      "invoice_number": "023/IV-AST/I/2015",
      "invoice_date": "21-01-2015",
      "kwitansi_number": "024/KW-AST/I/2015",
      "no_faktur_pajak": "010.000.15.62906389",
      "tgl_faktur_pajak": "21-01-2015",
      "matauang_total_tagihan": 3,
      "nilai_total_tagihan": "181500000",
      "matauang_invoice": 3,
      "invoice": "165000000",
      "matauang_pajak": 3,
      "pajak": "16500000",
      "nilai_pajak": "0",
      "kurs_pajak": "0",
      "keterangan": " Invoice diterima tanggal 21 Januari 2015, dan diterima oleh Ibu Yona ",
      "rekening_pembayaran": " Bank Mandiri ",
      "file": "invoice_2015_3.pdf"
    },
    {
      "id": 4,
      "tanggal": "26-01-2015",
      "ket_penagihan": "Penagihan Q4 : Maintenance & System Support - ATS Indosat Test Call System Maintenance for period 1 October 2014 - 31 December 2014. ",
      "date_issued": "26-01-2015",
      "date_due": "12-02-2015",
      "customer": "PT. Indosat Tbk",
      "no_npwp_customer": "01.000.502.3-092.000 ",
      "no_po_kontrak": "4800073411",
      "do_number": "-",
      "do_date": "-",
      "spp_number": "028/AD-AST/I/2015",
      "invoice_number": "029/IV-AST/I/2015",
      "invoice_date": "26-01-2015",
      "kwitansi_number": "030/KW-AST/I/2015",
      "no_faktur_pajak": "010.000.15.62906390",
      "tgl_faktur_pajak": "26-01-2015",
      "matauang_total_tagihan": 1,
      "nilai_total_tagihan": "20096.33",
      "matauang_invoice": 1,
      "invoice": "18269.39",
      "matauang_pajak": 1,
      "pajak": "1826.94",
      "nilai_pajak": "23006643",
      "kurs_pajak": "12593",
      "keterangan": " Invoice diterima tanggal 26 Januari 2015, dan diterima oleh Bp. Puji ",
      "rekening_pembayaran": " Bank Mandiri ",
      "file": "invoice_2015_4.pdf"
    },
    {
      "id": 5,
      "tanggal": "30-01-2015",
      "ket_penagihan": "Penagihan Q3 : Maintenance SIGOS 2014 Periode 1 Oktober 2014 - 31 Desember 2014",
      "date_issued": "30-01-2015",
      "date_due": "27-03-2015",
      "customer": "PT. XL Axiata",
      "no_npwp_customer": "01.345.276.8-092.000",
      "no_po_kontrak": "4500492724",
      "do_number": "-",
      "do_date": "-",
      "spp_number": "043/AD-AST/I/2015",
      "invoice_number": "044/IV-AST/I/2015",
      "invoice_date": "30-01-2015",
      "kwitansi_number": "045/KW-AST/I/2015",
      "no_faktur_pajak": "010.000.15.62906391",
      "tgl_faktur_pajak": "30-01-2015",
      "matauang_total_tagihan": 1,
      "nilai_total_tagihan": "27362.5",
      "matauang_invoice": 1,
      "invoice": "24875",
      "matauang_pajak": 1,
      "pajak": "2487.5",
      "nilai_pajak": "31098725",
      "kurs_pajak": "12502",
      "keterangan": " Invoice diterima tanggal 30 Januari 2015, dan diterima oleh Ibu Deli ",
      "rekening_pembayaran": " Bank Mandiri ",
      "file": "invoice_2015_5.pdf"
    },
    {
      "id": 6,
      "tanggal": "30-01-2015",
      "ket_penagihan": "Penagihan Q3 : Performance Test Service Sigos, MLU Service Sigos dan WLU Maintainance Sigos 2014 Periode 1 Oktober 2014 - 31 Desember 2014",
      "date_issued": "30-01-2015",
      "date_due": "27-03-2015",
      "customer": "PT. XL Axiata",
      "no_npwp_customer": "01.345.276.8-092.000",
      "no_po_kontrak": "4500494043",
      "do_number": "-",
      "do_date": "-",
      "spp_number": "046/AD-AST/I/2015",
      "invoice_number": "047/IV-AST/I/2015",
      "invoice_date": "30-01-2015",
      "kwitansi_number": "048/KW-AST/I/2015",
      "no_faktur_pajak": "010.000.15.62906392",
      "tgl_faktur_pajak": "30-01-2015",
      "matauang_total_tagihan": 1,
      "nilai_total_tagihan": "12333.75",
      "matauang_invoice": 1,
      "invoice": "11212.5",
      "matauang_pajak": 1,
      "pajak": "1121.25",
      "nilai_pajak": "14017868",
      "kurs_pajak": "12502",
      "keterangan": " Invoice diterima tanggal 30 Januari 2015, dan diterima oleh Ibu Deli ",
      "rekening_pembayaran": " Bank Mandiri ",
      "file": "invoice_2015_6.pdf"
    },
    {
      "id": 7,
      "tanggal": "06-02-2015",
      "ket_penagihan": "Payment 4 - Technical Support For International Roaming Active Call Testing (Period 1 October 2014 – 31 December 2014)",
      "date_issued": "06-02-2015",
      "date_due": "26-02-2015",
      "customer": "PT. Telekomunikasi Selular (Telkomsel)",
      "no_npwp_customer": "01.718.327.8-093.000",
      "no_po_kontrak": "PO No.: HOP130776",
      "do_number": "-",
      "do_date": "-",
      "spp_number": "053/AD-AST/II/2015",
      "invoice_number": "054/IV-AST/II/2015",
      "invoice_date": "06-02-2015",
      "kwitansi_number": "055/KW-AST/II/2015",
      "no_faktur_pajak": "010.000.15.62906393",
      "tgl_faktur_pajak": "06-02-2015",
      "matauang_total_tagihan": 1,
      "nilai_total_tagihan": "37348.3",
      "matauang_invoice": 1,
      "invoice": "33953",
      "matauang_pajak": 1,
      "pajak": "3395.3",
      "nilai_pajak": "42719665",
      "kurs_pajak": "12582",
      "keterangan": " Invoice diterima tanggal 06 Februari 2015, dan diterima oleh Bp. Wildan ",
      "rekening_pembayaran": " Bank Mandiri ",
      "file": "invoice_2015_7.pdf"
    },
    {
      "id": 8,
      "tanggal": "09-02-2015",
      "ket_penagihan": "3rd payment - Service Availability Monitoring System (SAMS), Support Maintenance for Period : 01 October 2014 - 31 December 2014",
      "date_issued": "09-02-2015",
      "date_due": "17-03-2015",
      "customer": "PT. Hutchison 3 Indonesia",
      "no_npwp_customer": "01.967.397.9-092.000",
      "no_po_kontrak": "200030093",
      "do_number": "-",
      "do_date": "-",
      "spp_number": "059/AD-AST/II/2015",
      "invoice_number": "027/IV-AST/I/2015",
      "invoice_date": "09-02-2015",
      "kwitansi_number": "060/KW-AST/II/2015",
      "no_faktur_pajak": "010.000.15.62906394",
      "tgl_faktur_pajak": "09-02-2015",
      "matauang_total_tagihan": 1,
      "nilai_total_tagihan": "15400",
      "matauang_invoice": 1,
      "invoice": "14000",
      "matauang_pajak": 1,
      "pajak": "1400",
      "nilai_pajak": "17614800",
      "kurs_pajak": "12582",
      "keterangan": " Invoice diterima tanggal 09 Februari 2015, dan diterima oleh Bp. Muji ",
      "rekening_pembayaran": " Bank Mandiri ",
      "file": "invoice_2015_8.pdf"
    },
    {
      "id": 9,
      "tanggal": "14-04-2015",
      "ket_penagihan": "Penagihan DP 50% (VCL-STM-1 63E1 SDH Multiplexer)",
      "date_issued": "14-04-2015",
      "date_due": "18-05-2015",
      "customer": "PT. Sampoerna Telekomunikasi Indonesia",
      "no_npwp_customer": "01.764.485.7-058.000",
      "no_po_kontrak": "115209",
      "do_number": "-",
      "do_date": "-",
      "spp_number": "098/AD-AST/IV/2015",
      "invoice_number": "099/IV-AST/IV/2015",
      "invoice_date": "14-04-2015",
      "kwitansi_number": "100/KW-AST/IV/2015",
      "no_faktur_pajak": "010.001.15.11550575",
      "tgl_faktur_pajak": "14-04-2015",
      "matauang_total_tagihan": 1,
      "nilai_total_tagihan": "1922.8",
      "matauang_invoice": 1,
      "invoice": "1748",
      "matauang_pajak": 1,
      "pajak": "174.8",
      "nilai_pajak": "2276071",
      "kurs_pajak": "13021",
      "keterangan": " Invoice diterima tanggal 14 April 2015, dan diterima oleh Bp. Endiy ",
      "rekening_pembayaran": " BRI ",
      "file": "invoice_2015_9.pdf"
    },
    {
      "id": 10,
      "tanggal": "22-04-2015",
      "ket_penagihan": "Penagihan Q4 : Performance Test Service Sigos, MLU Service Sigos dan WLU Maintainance Sigos 2014 Periode 1 Januari 2015 - 31 Maret 2015",
      "date_issued": "22-04-2015",
      "date_due": "26-05-2015",
      "customer": "PT. XL Axiata",
      "no_npwp_customer": "01.345.276.8-092.000",
      "no_po_kontrak": "4500494043",
      "do_number": "-",
      "do_date": "-",
      "spp_number": "105/AD-AST/IV/2015",
      "invoice_number": "106/IV-AST/IV/2015",
      "invoice_date": "22-04-2015",
      "kwitansi_number": "107/KW-AST/IV/2015",
      "no_faktur_pajak": "010.001.15.11550576",
      "tgl_faktur_pajak": "22-04-2015",
      "matauang_total_tagihan": 1,
      "nilai_total_tagihan": "12333.75",
      "matauang_invoice": 1,
      "invoice": "11212.5",
      "matauang_pajak": 1,
      "pajak": "1121.25",
      "nilai_pajak": "14456276",
      "kurs_pajak": "12893",
      "keterangan": " Invoice diterima tanggal 22 April 2015, dan diterima oleh Acc Payable ",
      "rekening_pembayaran": " Bank Mandiri ",
      "file": "invoice_2015_10.pdf"
    }
  ]
}


mock.onGet('/api/invoice-customers').reply(() => {
  return [200, data.all]
})
