import mock from '@/fake-db/mock.js'

const data = {
  active: [
    {
      "id": 1,
      "id_position": 2,
      "title": "[XL] Reactivate IuPS Local Unit",
      "description": "Instalasi dan konfigurasi Iu-LU di XL Cibitung",
      "date": "17-02-2016 09:41:02",
      "pic1": {
        "nik": 16715028,
        "id_jabatan": 1,
        "name": "Wahyu Prasetyo Indiarto",
        "id_card_type": "",
        "id_card_number": "",
        "place_birth": "Purworejo",
        "date_birth": "11-03-1967",
        "sex": 1,
        "address": "Kav DKI Blok E.8/1 Rt.009 / Rw.011, Pondok Kelapa, Duren Sawit - Jakarta Timur",
        "postal_code": 0,
        "city": "",
        "phone": "",
        "mobile1": "6282122068063",
        "mobile2": "",
        "mobile3": "",
        "email": "",
        "foto": ""
      },
      "pic2": {
        "nik": 16715028,
        "id_jabatan": 1,
        "name": "Wahyu Prasetyo Indiarto",
        "id_card_type": "",
        "id_card_number": "",
        "place_birth": "Purworejo",
        "date_birth": "11-03-1967",
        "sex": 1,
        "address": "Kav DKI Blok E.8/1 Rt.009 / Rw.011, Pondok Kelapa, Duren Sawit - Jakarta Timur",
        "postal_code": 0,
        "city": "",
        "phone": "",
        "mobile1": "6282122068063",
        "mobile2": "",
        "mobile3": "",
        "email": "",
        "foto": ""
      },
      "status": 5,
      "update_at": null
    },
    {
      "id": 2,
      "id_position": 2,
      "title": "[Telkomsel] Replace HDD CUX",
      "description": "HDD CUX ada error di RAID system, namun tidak mengganggu operasional SITE",
      "date": "17-02-2016 11:03:13",
      "pic1": {
        "nik": 16715028,
        "id_jabatan": 1,
        "name": "Wahyu Prasetyo Indiarto",
        "id_card_type": "",
        "id_card_number": "",
        "place_birth": "Purworejo",
        "date_birth": "11-03-1967",
        "sex": 1,
        "address": "Kav DKI Blok E.8/1 Rt.009 / Rw.011, Pondok Kelapa, Duren Sawit - Jakarta Timur",
        "postal_code": 0,
        "city": "",
        "phone": "",
        "mobile1": "6282122068063",
        "mobile2": "",
        "mobile3": "",
        "email": "",
        "foto": ""
      },
      "pic2": {
        "nik": 16715028,
        "id_jabatan": 1,
        "name": "Wahyu Prasetyo Indiarto",
        "id_card_type": "",
        "id_card_number": "",
        "place_birth": "Purworejo",
        "date_birth": "11-03-1967",
        "sex": 1,
        "address": "Kav DKI Blok E.8/1 Rt.009 / Rw.011, Pondok Kelapa, Duren Sawit - Jakarta Timur",
        "postal_code": 0,
        "city": "",
        "phone": "",
        "mobile1": "6282122068063",
        "mobile2": "",
        "mobile3": "",
        "email": "",
        "foto": ""
      },
      "status": 5,
      "update_at": null
    },
    {
      "id": 3,
      "id_position": 2,
      "title": "[XL] SITE Update 2_8 to 2_10",
      "description": "SITE update dari versi 2_8 ke 2_10",
      "date": "17-02-2016 16:25:17",
      "pic1": {
        "nik": 16715028,
        "id_jabatan": 1,
        "name": "Wahyu Prasetyo Indiarto",
        "id_card_type": "",
        "id_card_number": "",
        "place_birth": "Purworejo",
        "date_birth": "11-03-1967",
        "sex": 1,
        "address": "Kav DKI Blok E.8/1 Rt.009 / Rw.011, Pondok Kelapa, Duren Sawit - Jakarta Timur",
        "postal_code": 0,
        "city": "",
        "phone": "",
        "mobile1": "6282122068063",
        "mobile2": "",
        "mobile3": "",
        "email": "",
        "foto": ""
      },
      "pic2": {
        "nik": 16715028,
        "id_jabatan": 1,
        "name": "Wahyu Prasetyo Indiarto",
        "id_card_type": "",
        "id_card_number": "",
        "place_birth": "Purworejo",
        "date_birth": "11-03-1967",
        "sex": 1,
        "address": "Kav DKI Blok E.8/1 Rt.009 / Rw.011, Pondok Kelapa, Duren Sawit - Jakarta Timur",
        "postal_code": 0,
        "city": "",
        "phone": "",
        "mobile1": "6282122068063",
        "mobile2": "",
        "mobile3": "",
        "email": "",
        "foto": ""
      },
      "status": 5,
      "update_at": "2016-12-30 14:09:56"
    },
    {
      "id": 4,
      "id_position": 2,
      "title": "Troubleshoot Mail Server Argasolusi",
      "description": "Troubleshoot Mail Server Argasolusi",
      "date": "17-02-2016 16:43:30",
      "pic1": {
        "nik": 16715028,
        "id_jabatan": 1,
        "name": "Wahyu Prasetyo Indiarto",
        "id_card_type": "",
        "id_card_number": "",
        "place_birth": "Purworejo",
        "date_birth": "11-03-1967",
        "sex": 1,
        "address": "Kav DKI Blok E.8/1 Rt.009 / Rw.011, Pondok Kelapa, Duren Sawit - Jakarta Timur",
        "postal_code": 0,
        "city": "",
        "phone": "",
        "mobile1": "6282122068063",
        "mobile2": "",
        "mobile3": "",
        "email": "",
        "foto": ""
      },
      "pic2": {
        "nik": 16715028,
        "id_jabatan": 1,
        "name": "Wahyu Prasetyo Indiarto",
        "id_card_type": "",
        "id_card_number": "",
        "place_birth": "Purworejo",
        "date_birth": "11-03-1967",
        "sex": 1,
        "address": "Kav DKI Blok E.8/1 Rt.009 / Rw.011, Pondok Kelapa, Duren Sawit - Jakarta Timur",
        "postal_code": 0,
        "city": "",
        "phone": "",
        "mobile1": "6282122068063",
        "mobile2": "",
        "mobile3": "",
        "email": "",
        "foto": ""
      },
      "status": 5,
      "update_at": "2016-10-19 13:59:13"
    },
    {
      "id": 5,
      "id_position": 4,
      "title": "SIGOS on-site Forum Ritz Carlton Jakarta, 15 Maret 2016",
      "description": "Pada tgl 16 Feb 2016 sudah dibook 2 meeting room (full day) untuk event SIGOS on-site Forum di Ritz Carlton Jakarta tgl 15 Mar 2016.\r\n\r\nRoom 1 : Revenue Assurance / Fraud\r\nRoom 2 : LTE\r\n\r\nKapasitas sekitar 25 orang / room.",
      "date": "24-02-2016 10:25:00",
      "pic1": {
        "nik": 16715028,
        "id_jabatan": 1,
        "name": "Wahyu Prasetyo Indiarto",
        "id_card_type": "",
        "id_card_number": "",
        "place_birth": "Purworejo",
        "date_birth": "11-03-1967",
        "sex": 1,
        "address": "Kav DKI Blok E.8/1 Rt.009 / Rw.011, Pondok Kelapa, Duren Sawit - Jakarta Timur",
        "postal_code": 0,
        "city": "",
        "phone": "",
        "mobile1": "6282122068063",
        "mobile2": "",
        "mobile3": "",
        "email": "",
        "foto": ""
      },
      "pic2": {
        "nik": 16715028,
        "id_jabatan": 1,
        "name": "Wahyu Prasetyo Indiarto",
        "id_card_type": "",
        "id_card_number": "",
        "place_birth": "Purworejo",
        "date_birth": "11-03-1967",
        "sex": 1,
        "address": "Kav DKI Blok E.8/1 Rt.009 / Rw.011, Pondok Kelapa, Duren Sawit - Jakarta Timur",
        "postal_code": 0,
        "city": "",
        "phone": "",
        "mobile1": "6282122068063",
        "mobile2": "",
        "mobile3": "",
        "email": "",
        "foto": ""
      },
      "status": 5,
      "update_at": "2016-03-23 10:17:44"
    }
  ]
}


mock.onGet('/api/jobs/active').reply(() => {
  return [200, data.active]
})
