/*=========================================================================================
  File Name: moduleSparepart.js
  Description: sparepart Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleBrandEquipmentState.js";
import mutations from "./moduleBrandEquipmentMutations.js";
import actions from "./moduleBrandEquipmentActions.js";
import getters from "./moduleBrandEquipmentGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
