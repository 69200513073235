/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {

  // Fetch
  fetchItems ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (payload.params) {
        params = payload.params;
      }
      axios.get('/requests', { params })
        .then((response) => {
          commit('SET_ITEMS', response.data.values.items)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDrivers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/requests/drivers', {  })
        .then((response) => {
          commit('SET_DRIVERS', response.data.values)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchMessengers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/requests/messengers', {  })
        .then((response) => {
          commit('SET_MESSENGERS', response.data.values)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeItem ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/requests', payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItem ({ commit }, payload) {
    let url = `/requests/${payload.id}`;
    if (payload.finish) {
      url = `/requests/${payload.id}/finish`;
    }

    return new Promise((resolve, reject) => {
      axios.put(url, payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeItem ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/requests/${payload}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }

}
