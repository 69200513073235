/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/authService'

import firebase from 'firebase/app'
import 'firebase/auth'
import i18n from "@/i18n/i18n";

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/dashboard",
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("./views/Dashboard.vue"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        // =============================================================================
        // Incoming Letter
        // =============================================================================
        {
          path: "/incoming-letter",
          name: "incoming-letter",
          component: () =>
            import("./views/pages/IncomingLetter/IncomingLetterIndex.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `List ${i18n.t("IncomingLetter")}`, active: true },
            ],
            pageTitle: i18n.t("IncomingLetter"),
            auth: true,
          },
        },
        {
          path: "/incoming-letter/create",
          name: "incoming-letter-create",
          component: () =>
            import("./views/pages/IncomingLetter/IncomingLetterForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("IncomingLetter")}`,
                url: "/incoming-letter",
              },
              { title: i18n.t("Add"), active: true },
            ],
            pageTitle: i18n.t("IncomingLetter"),
            parent: "incoming-letter",
            auth: true,
          },
        },
        {
          path: "/incoming-letter/:id/detail",
          name: "incoming-letter-show",
          component: () =>
            import("./views/pages/IncomingLetter/IncomingLetterDetail.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("IncomingLetter")}`,
                url: "/incoming-letter",
              },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("IncomingLetter"),
            parent: "incoming-letter",
            auth: true,
          },
        },
        {
          path: "/incoming-letter/:id/edit",
          name: "incoming-letter-edit",
          component: () =>
            import("./views/pages/IncomingLetter/IncomingLetterForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("IncomingLetter")}`,
                url: "/incoming-letter",
              },
              { title: i18n.t("Edit"), active: true },
            ],
            pageTitle: i18n.t("IncomingLetter"),
            parent: "incoming-letter",
            auth: true,
          },
        },

        // =============================================================================
        // Outgoing Letter
        // =============================================================================
        {
          path: "/outgoing-letter",
          name: "outgoing-letter",
          component: () =>
            import("./views/pages/OutGoingLetter/OutGoingLetterIndex.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `List ${i18n.t("OutGoingLetter")}`, active: true },
            ],
            pageTitle: i18n.t("OutGoingLetter"),
            auth: true,
          },
        },
        {
          path: "/outgoing-letter/create/:year",
          name: "outgoing-letter-create",
          component: () =>
            import("./views/pages/OutGoingLetter/OutGoingLetterForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("OutGoingLetter")}`,
                url: "/outgoing-letter",
              },
              { title: i18n.t("Add"), active: true },
            ],
            pageTitle: i18n.t("OutGoingLetter"),
            parent: "outgoing-letter",
            auth: true,
          },
        },
        {
          path: "/outgoing-letter/:id/detail",
          name: "outgoing-letter-show",
          component: () =>
            import("./views/pages/OutGoingLetter/OutGoingLetterDetail.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("OutGoingLetter")}`,
                url: "/outgoing-letter",
              },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("OutGoingLetter"),
            parent: "outgoing-letter",
            auth: true,
          },
        },
        {
          path: "/outgoing-letter/:id/edit",
          name: "outgoing-letter-edit",
          component: () =>
            import("./views/pages/OutGoingLetter/OutGoingLetterForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("OutGoingLetter")}`,
                url: "/outgoing-letter",
              },
              { title: i18n.t("Edit"), active: true },
            ],
            pageTitle: i18n.t("OutGoingLetter"),
            parent: "outgoing-letter",
            auth: true,
          },
        },

        // =============================================================================
        // Invoice Customer
        // =============================================================================
        {
          path: "/invoice-customer",
          name: "invoice-customer",
          component: () =>
            import("./views/pages/InvoiceCustomer/InvoiceCustomerIndex.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `List ${i18n.t("InvoiceCustomer")}`, active: true },
            ],
            pageTitle: i18n.t("InvoiceCustomer"),
            parent: "invoice-customer",
            auth: true,
          },
        },
        {
          path: "/invoice-customer/create",
          name: "invoice-customer-create",
          component: () =>
            import("./views/pages/InvoiceCustomer/Old/InvoiceCustomerForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("InvoiceCustomer")}`,
                url: "/invoice-customer",
              },
              { title: i18n.t("Add"), active: true },
            ],
            pageTitle: i18n.t("InvoiceCustomer"),
            parent: "invoice-customer",
            auth: true,
          },
        },
        {
          path: "/invoice-customer/:id/detail",
          name: "invoice-customer-show",
          component: () =>
            import("./views/pages/InvoiceCustomer/InvoiceCustomerDetail.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("InvoiceCustomer")}`,
                url: "/invoice-customer",
              },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("InvoiceCustomer"),
            parent: "invoice-customer",
            auth: true,
          },
        },
        {
          path: "/invoice-customer/:id/edit",
          name: "invoice-customer-edit",
          component: () =>
            import("./views/pages/InvoiceCustomer/Old/InvoiceCustomerForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("InvoiceCustomer")}`,
                url: "/invoice-customer",
              },
              { title: i18n.t("Edit"), active: true },
            ],
            pageTitle: i18n.t("InvoiceCustomer"),
            parent: "invoice-customer",
            auth: true,
          },
        },

        // =============================================================================
        // Proforma Invoice
        // =============================================================================
        {
          path: "/proforma-invoice",
          name: "proforma-invoice",
          component: () =>
            import("./views/pages/ProformaInvoice/ProformaInvoiceIndex.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: i18n.t(`List ${i18n.t("ProformaInvoice")}`),
                active: true,
              },
            ],
            pageTitle: i18n.t("ProformaInvoice"),
            auth: true,
          },
        },
        {
          path: "/proforma-invoice/create",
          name: "proforma-invoice-create",
          component: () =>
            import("./views/pages/ProformaInvoice/ProformaInvoiceForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("ProformaInvoice")}`,
                url: "/proforma-invoice",
              },
              { title: i18n.t("Add"), active: true },
            ],
            pageTitle: i18n.t("ProformaInvoice"),
            parent: "proforma-invoice",
            auth: true,
          },
        },
        {
          path: "/proforma-invoice/:id/detail",
          name: "proforma-invoice-show",
          component: () =>
            import("./views/pages/ProformaInvoice/ProformaInvoiceDetail.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("ProformaInvoice")}`,
                url: "/proforma-invoice",
              },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("ProformaInvoice"),
            parent: "proforma-invoice",
            auth: true,
          },
        },
        {
          path: "/proforma-invoice/:id/edit",
          name: "proforma-invoice-edit",
          component: () =>
            import("./views/pages/ProformaInvoice/ProformaInvoiceForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("ProformaInvoice")}`,
                url: "/proforma-invoice",
              },
              { title: i18n.t("Edit"), active: true },
            ],
            pageTitle: i18n.t("ProformaInvoice"),
            parent: "proforma-invoice",
            auth: true,
          },
        },

        // PO from Customer
        // =============================================================================
        {
          path: "/purchase-order-customer",
          name: "purchase",
          component: () => import("./views/pages/Purchase/PurchaseIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("PurchaseOrderCustomer")}`,
                active: true,
              },
            ],
            pageTitle: i18n.t("PurchaseOrderCustomer"),
            auth: true,
          },
        },
        {
          path: "/purchase-order-customer/create",
          name: "purchase-create",
          component: () => import("./views/pages/Purchase/PurchaseForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("PurchaseOrderCustomer")}`,
                url: "/purchase-order-customer",
              },
              { title: i18n.t("Add"), active: true },
            ],
            pageTitle: i18n.t("PurchaseOrderCustomer"),
            parent: "purchase-order-customer",
            auth: true,
          },
        },
        {
          path: "/purchase-order-customer/:id/detail",
          name: "purchase-show",
          component: () => import("./views/pages/Purchase/PurchaseDetail.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("PurchaseOrderCustomer")}`,
                url: "/purchase-order-customer",
              },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("PurchaseOrderCustomer"),
            parent: "purchase-order-customer",
            auth: true,
          },
        },
        {
          path: "/purchase-order-customer/:id/edit",
          name: "purchase-edit",
          component: () => import("./views/pages/Purchase/PurchaseForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("PurchaseOrderCustomer")}`,
                url: "/purchase-order-customer",
              },
              { title: i18n.t("Edit"), active: true },
            ],
            pageTitle: i18n.t("PurchaseOrderCustomer"),
            parent: "purchase-order-customer",
            auth: true,
          },
        },

        // PO to Principle
        // =============================================================================
        {
          path: "/purchase-to-principle",
          name: "purchase-to-principle",
          component: () =>
            import("./views/pages/PurchasePrinciple/PurchasePrincipleIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `List ${i18n.t("PurchaseToPrinciple")}`, active: true },
            ],
            pageTitle: i18n.t("PurchaseToPrinciple"),
            auth: true,
          },
        },
        {
          path: "/purchase-to-principle/create",
          name: "purchase-to-principle-create",
          component: () =>
            import("./views/pages/PurchasePrinciple/PurchasePrincipleForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("PurchaseToPrinciple")}`,
                url: "/purchase-to-principle",
              },
              { title: i18n.t("Add"), active: true },
            ],
            pageTitle: i18n.t("PurchaseToPrinciple"),
            parent: "purchase-to-principle",
            auth: true,
          },
        },
        {
          path: "/ppurchase-to-principle/:id/detail",
          name: "purchase-to-principle-show",
          component: () =>
            import(
              "./views/pages/PurchasePrinciple/PurchasePrincipleDetail.vue"
            ),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("PurchaseToPrinciple")}`,
                url: "/purchase-to-principle",
              },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("PurchaseToPrinciple"),
            parent: "purchase-to-principle",
            auth: true,
          },
        },
        {
          path: "/purchase-to-principle/:id/edit",
          name: "purchase-to-principle-edit",
          component: () =>
            import("./views/pages/PurchasePrinciple/PurchasePrincipleForm.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `List ${i18n.t("PurchaseToPrinciple")}`,
                url: "/purchase-to-principle",
              },
              { title: i18n.t("Edit"), active: true },
            ],
            pageTitle: i18n.t("PurchaseToPrinciple"),
            parent: "purchase-to-principle",
            auth: true,
          },
        },

        // =============================================================================
        // Quotation
        // =============================================================================
        {
          path: "/quotation",
          name: "quotation",
          component: () => import("./views/pages/Quotation/QuotationIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("Quotation")} List`, active: true },
            ],
            pageTitle: i18n.t("Quotation"),
            auth: true,
          },
        },
        {
          path: "/quotation/create",
          name: "quotation-create",
          component: () => import("./views/pages/Quotation/QuotationForm"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("Quotation")} List`, url: "/quotation" },
              { title: i18n.t("Add"), active: true },
            ],
            pageTitle: i18n.t("Quotation"),
            parent: "quotation",
            auth: true,
          },
        },
        {
          path: "/quotation/:id/edit",
          name: "quotation-edit",
          component: () => import("./views/pages/Quotation/QuotationForm"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("Quotation")} List`, url: "/quotation" },
              { title: i18n.t("Edit"), active: true },
            ],
            pageTitle: i18n.t("Quotation"),
            parent: "quotation",
            auth: true,
          },
        },
        {
          path: "/quotation/:id/detail",
          name: "quotation-show",
          component: () => import("./views/pages/Quotation/QuotationDetail"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("Quotation")} List`, url: "/quotation" },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("Quotation"),
            parent: "quotation",
            auth: true,
          },
        },

        // =============================================================================
        // Request Driver
        // =============================================================================
        {
          path: "/request-driver",
          name: "request-driver",
          component: () =>
            import("./views/pages/RequestDriver/RequestDriverIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: i18n.t("RequestDriver"), active: true },
            ],
            pageTitle: i18n.t("RequestDriver"),
            parent: "request-driver",
            auth: true,
          },
        },

        // =============================================================================
        // Request Messenger
        // =============================================================================
        {
          path: "/request-messenger",
          name: "request-messenger",
          component: () =>
            import("./views/pages/RequestMessenger/RequestMessengerIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: i18n.t("RequestMessenger"), active: true },
            ],
            pageTitle: i18n.t("RequestMessenger"),
            parent: "request-messenger",
            auth: true,
          },
        },

        // =============================================================================
        // Reminder
        // =============================================================================
        {
          path: "/reminder",
          name: "reminder",
          component: () => import("./views/pages/Reminder/ReminderIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: i18n.t("Reminder"), active: true },
            ],
            pageTitle: i18n.t("Reminder"),
            auth: true,
          },
        },
        {
          path: "/reminder/:id/detail",
          name: "reminder-show",
          component: () => import("./views/pages/Reminder/ReminderDetail"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: i18n.t("Reminder"), url: "/reminder" },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("Reminder"),
            parent: "reminder",
            auth: true,
          },
        },

        {
          path: "/absent",
          name: "see-absent",
          component: () => import("./views/pages/SeeAbsent/SeeAbsent"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: i18n.t("SeeAbsent"), active: true },
            ],
            pageTitle: i18n.t("SeeAbsent"),
            auth: true,
          },
        },

        {
          path: "/absent/report",
          name: "report-absent",
          component: () =>
            import("./views/pages/ReportAbsent/ReportAbsentIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: i18n.t("ReportAbsent"), active: true },
            ],
            pageTitle: i18n.t("ReportAbsent"),
            auth: true,
          },
        },

        // {
        //   path: '/technical/techteam-report',
        //   name: 'equipment-brand',
        //   component: () => import('./views/pages/Technical/TechteamReport/TechteamReportIndex'),
        //   meta: {
        //     rule: 'editor',
        //     auth: true
        //   }
        // },

        // {
        //   path: "/technical/equipment/status",
        //   name: "equipment-status",
        //   component: () => import("./views/pages/Technical/Status/StatusIndex"),
        //   meta: {
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        {
          path: "/technical/equipment/brand",
          name: "equipment-brand",
          component: () => import("./views/pages/Technical/Brand/BrandIndex"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/equipment/equipment",
          name: "equipment",
          component: () =>
            import("./views/pages/Technical/Equipment/EquipmentIndex"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/equipment/:id/detail",
          name: "equipment-show",
          component: () =>
            import("./views/pages/Technical/Equipment/EquipmentShow"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/equipment/:id/edit",
          name: "equipment-edit",
          component: () =>
            import("./views/pages/Technical/Equipment/EquipmentEdit"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/sparepart/manage",
          name: "sparepart-manage",
          component: () =>
            import("./views/pages/Technical/Sparepart/Manage/index"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/sparepart/report",
          name: "sparepart-report",
          component: () =>
            import("./views/pages/Technical/Sparepart/Report/index"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/sparepart/report/:id/detail",
          name: "sparepart-show",
          component: () =>
            import("./views/pages/Technical/Sparepart/Report/show"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `${i18n.t("Sparepart")} List`,
                url: "/technical/sparepart/report",
              },
              { title: `${i18n.t("Detail")}`, active: true },
            ],
            pageTitle: i18n.t("SparepartShow"),
            parent: "sparepart-report",
            auth: true,
          },
        },

        {
          path: "/technical/sparepart/report/:id/edit",
          name: "sparepart-edit",
          component: () =>
            import("./views/pages/Technical/Sparepart/Report/edit"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: `${i18n.t("Sparepart")} List`,
                url: "/technical/sparepart/report",
              },
              { title: `${i18n.t("Edit")}`, active: true },
            ],
            pageTitle: i18n.t("SparepartEdit"),
            parent: "sparepart-report",
            auth: true,
          },
        },

        // maintainance 19 february 2024 by Muhammad Arifin Habibi
        // gitlab: arifinhabibi
        /*
            About remake system database and backend technical - sparepart and equipment
        */

        // {
        //   path: "/technical/sparepart/clu/add",
        //   name: "sparepart-clu-add",
        //   component: () =>
        //     import("./views/pages/Technical/Equipment/Spareparts/CLU/Add"),
        //   meta: {
        //     pageTitle: i18n.t("AddCLU"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/clu/:id/edit",
        //   name: "sparepart-clu-edit",
        //   component: () =>
        //     import("./views/pages/Technical/Equipment/Spareparts/CLU/Edit"),
        //   meta: {
        //     pageTitle: i18n.t("EditCLU"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/clu/in",
        //   name: "sparepart-clu-in",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/CLU/IncomeItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("CLUIn"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/clu/out",
        //   name: "sparepart-clu-out",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/CLU/LeaveItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("CLUOut"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/handphone/add",
        //   name: "sparepart-handphone-add",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Handphone/Add"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("AddHandphone"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/handphone/:id/edit",
        //   name: "sparepart-handphone-edit",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Handphone/Edit"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("EditHandphone"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/handphone/in",
        //   name: "sparepart-handphone-in",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Handphone/IncomeItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("HandphoneIn"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/handphone/out",
        //   name: "sparepart-handphone-out",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Handphone/LeaveItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("HandphoneOut"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/hdd/add",
        //   name: "sparepart-hdd-add",
        //   component: () =>
        //     import("./views/pages/Technical/Equipment/Spareparts/HDD/Add"),
        //   meta: {
        //     pageTitle: i18n.t("AddHDD"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/hdd/:id/edit",
        //   name: "sparepart-hdd-edit",
        //   component: () =>
        //     import("./views/pages/Technical/Equipment/Spareparts/HDD/Edit"),
        //   meta: {
        //     pageTitle: i18n.t("EditHDD"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/hdd/in",
        //   name: "sparepart-hdd-in",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/HDD/IncomeItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("HDDIn"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/hdd/out",
        //   name: "sparepart-hdd-out",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/HDD/LeaveItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("HDDOut"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/interface/add",
        //   name: "sparepart-interface-add",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Interface/Add"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("AddInterface"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/interface/:id/edit",
        //   name: "sparepart-interface-edit",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Interface/Edit"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("EditInterface"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/interface/in",
        //   name: "sparepart-interface-in",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Interface/IncomeItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("InterfaceIn"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/interface/out",
        //   name: "sparepart-interface-out",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Interface/LeaveItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("InterfaceOut"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/power-supply/add",
        //   name: "sparepart-power-supply-add",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/PowerSupply/Add"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("AddPowerSupply"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/power-supply/:id/edit",
        //   name: "sparepart-power-supply-edit",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/PowerSupply/Edit"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("EditPowerSupply"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/power-supply/in",
        //   name: "sparepart-power-supply-in",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/PowerSupply/IncomeItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("PowerSupplyIn"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/power-supply/out",
        //   name: "sparepart-power-supply-out",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/PowerSupply/LeaveItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("PowerSupplyOut"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/simboard/add",
        //   name: "sparepart-simboard-add",
        //   component: () =>
        //     import("./views/pages/Technical/Equipment/Spareparts/Simboard/Add"),
        //   meta: {
        //     pageTitle: i18n.t("AddSimboard"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/simboard/:id/edit",
        //   name: "sparepart-simboard-edit",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Simboard/Edit"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("EditSimboard"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/simboard/in",
        //   name: "sparepart-simboard-in",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Simboard/IncomeItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("SimboardIn"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/sparepart/simboard/out",
        //   name: "sparepart-simboard-out",
        //   component: () =>
        //     import(
        //       "./views/pages/Technical/Equipment/Spareparts/Simboard/LeaveItem/Index"
        //     ),
        //   meta: {
        //     pageTitle: i18n.t("SimboardOut"),
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/equipment/delivered",
        //   name: "equipment-brand",
        //   component: () =>
        //     import("./views/pages/Technical/Delivered/DeliveredIndex"),
        //   meta: {
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: "/technical/monthly-report/hutchison-3",
        //   name: "monthly-report-hutchison-3",
        //   component: () =>
        //     import("./views/pages/Technical/MonthlyReport/MonthlyReportIndex"),
        //   meta: {
        //     rule: "editor",
        //     auth: true,
        //   },
        // },

        // {
        //   path: '/technical/monthly-report/indosat',
        //   name: 'monthly-report-indosat',
        //   component: () => import('./views/pages/Technical/MonthlyReport/MonthlyReportIndosat'),
        //   meta: {
        //     rule: 'editor',
        //     auth: true
        //   }
        // },

        // Route for Learning Center

        // {
        //   path: '/technical/learning-center',
        //   name: 'learning-center',
        //   component: () => import('./views/pages/Technical/LearningCenter/LearningCenterIndex'),
        //   meta: {
        //     pageTitle: i18n.t('LearningCenter'),
        //     rule: 'editor',
        //     auth: true
        //   }
        // },

        // {
        //   path: '/technical/learning-center/:id/detail',
        //   name: 'learning-center-detail',
        //   component: () => import('./views/pages/Technical/LearningCenter/LearningCenterDetail'),
        //   meta: {
        //     pageTitle: i18n.t('LearningCenter'),
        //     rule: 'editor',
        //     auth: true
        //   }
        // },

        // {
        //   path: '/technical/learning-center/:id/edit',
        //   name: 'learning-center-edit',
        //   component: () => import('./views/pages/Technical/LearningCenter/LearningCenterForm'),
        //   meta: {
        //     pageTitle: i18n.t('LearningCenter'),
        //     rule: 'editor',
        //     auth: true
        //   }
        // },

        // Route for Learning Center Category
        {
          path: "/technical/learning-center/category",
          name: "learning-center-category",
          component: () =>
            import(
              "./views/pages/Technical/LearningCenter/LearningCategory/LearningCategoryIndex.vue"
            ),
          meta: {
            pageTitle: i18n.t("Category"),
            rule: "editor",
            auth: true,
          },
        },

        // Route for Learning Center Knowledge Base
        {
          path: "/technical/learning-center/knowledge-base",
          name: "learning-center-knowledge-base",
          component: () =>
            import(
              "./views/pages/Technical/LearningCenter/KnowledgeBase/KnowledgeBaseIndex"
            ),
          meta: {
            pageTitle: i18n.t("KnowledgeBase"),
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/learning-center/knowledge-base/:categoryId",
          name: "learning-center-knowledge-base-theory",
          component: () =>
            import(
              "./views/pages/Technical/LearningCenter/KnowledgeBase/KnowledgeBaseTheory"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Knowledge Base",
                url: "/technical/learning-center/knowledge-base",
              },
              { title: "Knowledge Base Theory", active: true },
            ],
            pageTitle: i18n.t("KnowledgeBase"),
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/learning-center/knowledge-base/:id/detail",
          name: "learning-center-knowledge-base-detail",
          component: () =>
            import(
              "./views/pages/Technical/LearningCenter/KnowledgeBase/KnowledgeBaseDetail"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Knowledge Base",
                url: "/technical/learning-center/knowledge-base",
              },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("KnowledgeBase"),
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/learning-center/knowledge-base/create",
          name: "learning-center-knowledge-base-create",
          component: () =>
            import(
              "./views/pages/Technical/LearningCenter/KnowledgeBase/KnowledgeBaseForm"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Knowledge Base",
                url: "/technical/learning-center/knowledge-base",
              },
              { title: "Add", active: true },
            ],
            pageTitle: i18n.t("KnowledgeBase"),
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/learning-center/knowledge-base/:id/edit",
          name: "learning-center-knowledge-base-edit",
          component: () =>
            import(
              "./views/pages/Technical/LearningCenter/KnowledgeBase/KnowledgeBaseForm"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Knowledge Base",
                url: "/technical/learning-center/knowledge-base",
              },
              { title: "Edit", active: true },
            ],
            pageTitle: i18n.t("KnowledgeBase"),
            rule: "editor",
            auth: true,
          },
        },

        // Route for Issue Tracking
        {
          path: "/technical/learning-center/issue-tracking",
          name: "learning-center-issue-tracking",
          component: () =>
            import(
              "./views/pages/Technical/LearningCenter/IssueTracking/IssueTrackingIndex"
            ),
          meta: {
            pageTitle: i18n.t("IssueTracking"),
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/learning-center/issue-tracking/:id/detail",
          name: "learning-center-issue-tracking-detail",
          component: () =>
            import(
              "./views/pages/Technical/LearningCenter/IssueTracking/IssueTrackingDetail"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Issue Tracking",
                url: "/technical/learning-center/issue-tracking",
              },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("IssueTracking"),
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/learning-center/issue-tracking/create",
          name: "learning-center-issue-tracking-create",
          component: () =>
            import(
              "./views/pages/Technical/LearningCenter/IssueTracking/IssueTrackingForm"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Issue Tracking",
                url: "/technical/learning-center/issue-tracking",
              },
              { title: "Add", active: true },
            ],
            pageTitle: i18n.t("IssueTracking"),
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/technical/learning-center/issue-tracking/:id/edit",
          name: "learning-center-issue-tracking-edit",
          component: () =>
            import(
              "./views/pages/Technical/LearningCenter/IssueTracking/IssueTrackingForm"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Issue Tracking",
                url: "/technical/learning-center/issue-tracking",
              },
              { title: "Edit", active: true },
            ],
            pageTitle: i18n.t("IssueTracking"),
            rule: "editor",
            auth: true,
          },
        },

        // {
        //   path: '/technical/helpdesk-schedule',
        //   name: 'equipment-helpdesk-schedule',
        //   component: () => import('./views/pages/Technical/HelpdeskSchedule/HelpdeskScheduleIndex'),
        //   meta: {
        //     rule: 'editor',
        //     auth: true
        //   }
        // },

        {
          path: "/technical/ticketing/dashboard",
          name: "ticketing-dashboard",
          component: () => import("./views/ticketing-systems/Dashboard.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Ticketing System",
                url: "/technical/ticketing/dashboard",
              },
              { title: "Dashboard", active: true },
            ],
            pageTitle: i18n.t("Dashboard"),
            // parent: 'outgoing-letter',
            auth: true,
          },
        },

        {
          path: "/technical/ticketing/trouble-ticket/data",
          name: "ticketing-trouble-ticket",
          component: () =>
            import("./views/ticketing-systems/trouble-ticket/Index.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Ticketing System",
                url: "/technical/ticketing/dashboard",
              },
              { title: "Trouble Ticket", active: true },
            ],
            pageTitle: i18n.t("TroubleTicket"),
            // parent: 'outgoing-letter',
            auth: true,
          },
        },

        {
          path: "/technical/ticketing/trouble-ticket/:id/detail",
          name: "trouble-ticket-detail",
          component: () =>
            import("./views/ticketing-systems/trouble-ticket/Detail.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Ticketing System",
                url: "/technical/ticketing/trouble-ticket/data",
              },
              { title: "Detail Trouble Ticket", active: true },
            ],
            pageTitle: i18n.t("TroubleTicket"),
            parent: "ticketing-trouble-ticket",
            auth: true,
          },
        },

        {
          path: "/technical/ticketing/trouble-ticket/new",
          name: "ticketing-trouble-ticket",
          component: () =>
            import("./views/ticketing-systems/trouble-ticket/Add.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Ticketing System",
                url: "/technical/ticketing/dashboard",
              },
              { title: "New Trouble Ticket", active: true },
            ],
            pageTitle: i18n.t("TroubleTicket"),
            // parent: 'outgoing-letter',
            auth: true,
          },
        },
        {
          path: "/technical/ticketing/trouble-ticket/priority",
          name: "ticketing-trouble-ticket",
          component: () =>
            import("./views/ticketing-systems/trouble-ticket/Priority.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Ticketing System",
                url: "/technical/ticketing/dashboard",
              },
              { title: "Trouble Ticket Priority", active: true },
            ],
            pageTitle: i18n.t("TroubleTicket"),
            // parent: 'outgoing-letter',
            auth: true,
          },
        },
        {
          path: "/technical/ticketing/trouble-ticket/product",
          name: "ticketing-trouble-ticket",
          component: () =>
            import("./views/ticketing-systems/trouble-ticket/Product.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Ticketing System",
                url: "/technical/ticketing/dashboard",
              },
              { title: "Trouble Ticket Product", active: true },
            ],
            pageTitle: i18n.t("TroubleTicket"),
            // parent: 'outgoing-letter',
            auth: true,
          },
        },

        {
          path: "/technical/ticketing/report/daily",
          name: "ticketing-report",
          component: () => import("./views/ticketing-systems/report/Daily.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Ticketing System",
                url: "/technical/ticketing/dashboard",
              },
              { title: "Report Daily", active: true },
            ],
            pageTitle: i18n.t("TroubleTicket"),
            // parent: 'outgoing-letter',
            auth: true,
          },
        },
        {
          path: "/technical/ticketing/report/weekly",
          name: "ticketing-report",
          component: () =>
            import("./views/ticketing-systems/report/Weekly.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Ticketing System",
                url: "/technical/ticketing/dashboard",
              },
              { title: "Report Weekly", active: true },
            ],
            pageTitle: i18n.t("TroubleTicket"),
            // parent: 'outgoing-letter',
            auth: true,
          },
        },
        {
          path: "/technical/ticketing/report/monthly",
          name: "ticketing-report",
          component: () =>
            import("./views/ticketing-systems/report/Monthly.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Ticketing System",
                url: "/technical/ticketing/dashboard",
              },
              { title: "Report Monthly", active: true },
            ],
            pageTitle: i18n.t("TroubleTicket"),
            // parent: 'outgoing-letter',
            auth: true,
          },
        },
        {
          path: "/technical/ticketing/report/yearly",
          name: "ticketing-report",
          component: () =>
            import("./views/ticketing-systems/report/Yearly.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              {
                title: "Ticketing System",
                url: "/technical/ticketing/dashboard",
              },
              { title: "Report Yearly", active: true },
            ],
            pageTitle: i18n.t("TroubleTicket"),
            // parent: 'outgoing-letter',
            auth: true,
          },
        },

        {
          path: "/sales/dashboard",
          name: "forecast",
          component: () =>
            import("./views/pages/Sales/Dashboard/DashboardIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: i18n.t("Sales"), active: true },
            ],
            pageTitle: i18n.t("Dashboard"),
            auth: true,
          },
        },

        {
          path: "/sales/forecast",
          name: "forecast",
          component: () => import("./views/pages/Sales/Forecast/ForecastIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: i18n.t("Sales"), url: "/sales/dashboard" },
              { title: i18n.t("Forecast"), active: true },
            ],
            pageTitle: i18n.t("Forecast"),
            auth: true,
          },
        },

        {
          path: "/sales/customer-contact",
          name: "customer-contact",
          component: () =>
            import("./views/pages/Sales/CustomerContact/CustomerContactIndex"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/sales/report/:tipe",
          name: "customer-contact",
          component: () => import("./views/pages/Sales/Report/ReportIndex"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/sales/purchase-order/:tipe",
          name: "customer-contact",
          component: () =>
            import("./views/pages/Sales/PurchaseOrder/PurchaseOrderIndex"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/sales/admin/privileges",
          name: "admin-privileges",
          component: () =>
            import("./views/pages/Sales/Admin/Privileges/PrivilegesIndex"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/sales/admin/accounts",
          name: "admin-accounts",
          component: () =>
            import("./views/pages/Sales/Admin/Accounts/AccountsIndex"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/sales/admin/merk",
          name: "admin-merk",
          component: () => import("./views/pages/Sales/Admin/Merk/MerkIndex"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/sales/admin/customer",
          name: "admin-customer",
          component: () =>
            import("./views/pages/Sales/Admin/Customer/CustomerIndex"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/sales/admin/statistic",
          name: "admin-statistic",
          component: () =>
            import("./views/pages/Sales/Admin/Statistic/StatisticIndex"),
          meta: {
            rule: "editor",
            auth: true,
          },
        },

        {
          path: "/test",
          name: "test",
          component: () => import("./views/pages/Test"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Test", active: true },
            ],
            auth: true,
          },
        },

        // =============================================================================
        // Employee
        // =============================================================================
        {
          path: "/employee",
          name: "employee",
          component: () => import("./views/pages/Employee/EmployeeIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: i18n.t("EmployeeData"), active: true },
            ],
            pageTitle: i18n.t("EmployeeData"),
            auth: true,
          },
        },
        {
          path: "/employee/:id/detail",
          name: "employee-show",
          component: () => import("./views/pages/Employee/EmployeeDetail"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: i18n.t("EmployeeData"), url: "/employee" },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("EmployeeData"),
            parent: "employee",
            auth: true,
          },
        },

        // =============================================================================
        // Job Tracking
        // =============================================================================
        {
          path: "/job-tracking",
          name: "job-tracking",
          component: () => import("./views/pages/JobTracking/JobIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("JobTracking")} List`, active: true },
            ],
            pageTitle: i18n.t("JobTracking"),
            auth: true,
          },
        },
        {
          path: "/job-tracking/create",
          name: "job-tracking-create",
          component: () => import("./views/pages/JobTracking/JobForm"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("JobTracking")} List`, url: "/job-tracking" },
              { title: i18n.t("Add"), active: true },
            ],
            pageTitle: i18n.t("JobTracking"),
            parent: "job-tracking",
            auth: true,
          },
        },
        {
          path: "/job-tracking/:id/edit",
          name: "job-tracking-edit",
          component: () => import("./views/pages/JobTracking/JobForm"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("JobTracking")} List`, url: "/job-tracking" },
              { title: i18n.t("Edit"), active: true },
            ],
            pageTitle: i18n.t("JobTracking"),
            parent: "job-tracking",
            auth: true,
          },
        },
        {
          path: "/job-tracking/:id/detail",
          name: "job-tracking-show",
          component: () => import("./views/pages/JobTracking/JobDetail"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("JobTracking")} List`, url: "/job-tracking" },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("JobTracking"),
            parent: "job-tracking",
            auth: true,
          },
        },

        // =============================================================================
        // Todo List
        // =============================================================================
        {
          path: "/todo-list",
          name: "todo-list",
          component: () => import("./views/pages/TodoList/TodoListIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("TodoList")} List`, active: true },
            ],
            pageTitle: i18n.t("TodoList"),
            auth: true,
          },
        },
        {
          path: "/todo-list/create",
          name: "todo-list-create",
          component: () => import("./views/pages/TodoList/TodoListForm"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("TodoList")} List`, url: "/todo-list" },
              { title: i18n.t("Add"), active: true },
            ],
            pageTitle: i18n.t("TodoList"),
            parent: "todo-list",
            auth: true,
          },
        },
        {
          path: "/todo-list/:id/edit",
          name: "todo-list-edit",
          component: () => import("./views/pages/TodoList/TodoListForm"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("TodoList")} List`, url: "/todo-list" },
              { title: i18n.t("Edit"), active: true },
            ],
            pageTitle: i18n.t("TodoList"),
            parent: "todo-list",
            auth: true,
          },
        },
        {
          path: "/todo-list/:id/detail",
          name: "todo-list-show",
          component: () => import("./views/pages/TodoList/TodoListDetail"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("TodoList")} List`, url: "/todo-list" },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("TodoList"),
            parent: "todo-list",
            auth: true,
          },
        },

        // =============================================================================
        // View Report
        // =============================================================================
        {
          path: "/view-report",
          name: "view-report",
          component: () => import("./views/pages/ViewReport/ViewReportIndex"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("ViewReport")} List`, active: true },
            ],
            pageTitle: i18n.t("ViewReport"),
            auth: true,
          },
        },
        {
          path: "/view-report/create",
          name: "view-report-create",
          component: () => import("./views/pages/ViewReport/ViewReportForm"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("ViewReport")} List`, url: "/view-report" },
              { title: i18n.t("Add"), active: true },
            ],
            pageTitle: i18n.t("ViewReport"),
            parent: "view-report",
            auth: true,
          },
        },
        {
          path: "/view-report/:id/edit",
          name: "view-report-edit",
          component: () => import("./views/pages/ViewReport/ViewReportForm"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("ViewReport")} List`, url: "/view-report" },
              { title: i18n.t("Edit"), active: true },
            ],
            pageTitle: i18n.t("ViewReport"),
            parent: "view-report",
            auth: true,
          },
        },
        {
          path: "/view-report/:id/detail",
          name: "view-report-show",
          component: () => import("./views/pages/ViewReport/ViewReportDetail"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("ViewReport")} List`, url: "/view-report" },
              { title: "Detail", active: true },
            ],
            pageTitle: i18n.t("ViewReport"),
            parent: "view-report",
            auth: true,
          },
        },

        // =============================================================================
        // Customer
        // =============================================================================

        {
          path: "/customer",
          name: "customer",
          component: () => import("@/views/pages/Customer/CustomerIndex.vue"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("Customer")} List`, active: true },
            ],
            pageTitle: i18n.t("Customer"),
            auth: true,
          },
        },

        {
          path: "/customer/:id/detail",
          name: "customer-show",
          component: () => import("./views/pages/Customer/CustomerDetail"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: `${i18n.t("Customer")} List`, url: "/customer" },
              { title: `${i18n.t("Contact")}`, active: true },
            ],
            pageTitle: i18n.t("CustomerContact"),
            parent: "customer",
            auth: true,
          },
        },

        // =============================================================================
        // Profile
        // =============================================================================
        {
          path: "/profile",
          name: "profile",
          component: () => import("./views/pages/Profile/Profile"),
          meta: {
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Profile", active: true },
            ],
            pageTitle: i18n.t("Profile"),
            auth: true,
          },
        },
      ],
    },

    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("@/views/Auth/Login.vue"),
          meta: {
            rule: "editor",
          },
        },

        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/register",
          name: "page-register",
          component: () => import("@/views/pages/register/Register.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/forgot-password",
          name: "page-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/comingsoon",
          name: "page-coming-soon",
          component: () => import("@/views/pages/ComingSoon.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-502",
          name: "page-error-502",
          component: () => import("@/views/pages/Error502.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/employee/:id/card",
          name: "employee-card",
          component: () => import("./components/CardEmployee.vue"),
          meta: {
            rule: "editor",
            pageTitle: i18n.t("EmployeeData"),
            // parent: "employee",
            auth: true,
          },
        },
      ],
    },

    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {

  if (to.meta.auth) {
    if (!localStorage.getItem('accessToken')) {
      return next('/login');
    }

    if (!auth.isAuthenticated()) {
      //   return next('/login');
    }
  }


  firebase.auth().onAuthStateChanged(() => {

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser

    // if (
    //     to.path === "/pages/login" ||
    //     to.path === "/pages/forgot-password" ||
    //     to.path === "/pages/error-404" ||
    //     to.path === "/pages/error-500" ||
    //     to.path === "/pages/register" ||
    //     to.path === "/callback" ||
    //     to.path === "/pages/comingsoon" ||
    //     (auth.isAuthenticated() || firebaseCurrentUser)
    // ) {
    //     return next();
    // }

    // If auth required, check login. If login fails redirect to login page
    if (to.meta.authRequired) {
      if (!(auth.isAuthenticated() || firebaseCurrentUser)) {
        router.push({ path: '/pages/login', query: { to: to.path } })
      }
    }

    return next()
    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });

  })

})

export default router;
