import mock from '@/fake-db/mock.js'

const data = {
  all: [
    {
      "id": 1,
      "tanggal": "18-10-2005",
      "nomor": "1079/C00-JEG/PRC/200",
      "no_kontrak": "-",
      "nama_customer": "PT. Indosat, Tbk.",
      "nama_proyek": "Operational dan maintenance tools",
      "currency_diskon": 3,
      "diskon": "0",
      "delivery_order": "-",
      "sn": "-",
      "file": "purchase_2016_1.pdf"
    },
    {
      "id": 2,
      "tanggal": "21-10-2005",
      "nomor": "5000008886",
      "no_kontrak": "-",
      "nama_customer": "PT. Indosat, Tbk.",
      "nama_proyek": "Pengadaan portable spectrum analyzer",
      "currency_diskon": 1,
      "diskon": "0",
      "delivery_order": "-",
      "sn": "-",
      "file": "purchase_2016_2.pdf"
    },
    {
      "id": 3,
      "tanggal": "23-12-2005",
      "nomor": "1171/C00-JEG/PRC/200",
      "no_kontrak": "-",
      "nama_customer": "PT. Indosat, Tbk.",
      "nama_proyek": "Pengadaan Battery kering",
      "currency_diskon": 3,
      "diskon": "0",
      "delivery_order": "002/AST-DO/I/2006 (9-Jan-2006)",
      "sn": "-",
      "file": "purchase_2016_3.pdf"
    },
    {
      "id": 4,
      "tanggal": "23-12-2005",
      "nomor": "1172/C00-JEG/PRC/200",
      "no_kontrak": "-",
      "nama_customer": "PT. Indosat, Tbk.",
      "nama_proyek": "Pengadaan Battery Tester Digital",
      "currency_diskon": 3,
      "diskon": "0",
      "delivery_order": "001/AST-DO/I/2006 (13-Jan-2006)",
      "sn": "-",
      "file": "purchase_2016_4.pdf"
    },
    {
      "id": 5,
      "tanggal": "23-12-2005",
      "nomor": "1173/C00-JEG/PRC/200",
      "no_kontrak": "-",
      "nama_customer": "PT. Indosat, Tbk.",
      "nama_proyek": "Pengadaan Inverter DC (-48 VDC) ke AC (220 VAC)",
      "currency_diskon": 3,
      "diskon": "0",
      "delivery_order": "002/AST-DO/I/2006 (30-Jan-2006)",
      "sn": "-",
      "file": "purchase_2016_5.pdf"
    },
    {
      "id": 6,
      "tanggal": "07-04-2006",
      "nomor": "TEL.50/HK.810/RDC-23/2006",
      "no_kontrak": "-",
      "nama_customer": "PT. Telekomunikasi Indonesia, Tbk.",
      "nama_proyek": "Pengadaan Modul Pos untuk Alat Jaringan IP Ukur ",
      "currency_diskon": 3,
      "diskon": "0",
      "delivery_order": "003/AST-DO/V/2006 (22-Mei-2006)",
      "sn": "-",
      "file": "003_DO_-_Tel_50_HK_810_RDC-23_2006.jpg,"
    },
    {
      "id": 7,
      "tanggal": "14-01-2016",
      "nomor": "200039639",
      "no_kontrak": "",
      "nama_customer": "PT. Hutchison 3 Indonesia",
      "nama_proyek": "Maintenance Contract",
      "currency_diskon": 3,
      "diskon": "0",
      "delivery_order": "-",
      "sn": "-",
      "file": "2016_01_14_-_PO_200039639_MC_(Apr_2015_-_Mar_2016).pdf,2016_01_14_PO_200039639_MC_Apr_2015_sd_Mar_2016.pdf,"
    },
    {
      "id": 8,
      "tanggal": "22-02-2016",
      "nomor": "4800171856",
      "no_kontrak": "5100002026",
      "nama_customer": "PT. Indosat, Tbk.",
      "nama_proyek": "SIGOS Global Roamer (Additional)",
      "currency_diskon": 3,
      "diskon": "0",
      "delivery_order": "-",
      "sn": "-",
      "file": "2016_02_22_4800171856_Global_Roamer_Test_Additional.PDF,"
    },
    {
      "id": 9,
      "tanggal": "07-04-2016",
      "nomor": "4800178681",
      "no_kontrak": "5100002026",
      "nama_customer": "PT. Indosat, Tbk.",
      "nama_proyek": "Maintenance Contract",
      "currency_diskon": 3,
      "diskon": "0",
      "delivery_order": "-",
      "sn": "-",
      "file": "2016_04_07_4800178681_Maintenance_Contract_Sigos_2016.PDF,"
    },
    {
      "id": 10,
      "tanggal": "11-05-2016",
      "nomor": "eHOP160483",
      "no_kontrak": "006/BC/PROC-02/LOG/2014 tgl 27 April 2016",
      "nama_customer": "PT. Telekomunikasi Selular (Telkomsel)",
      "nama_proyek": "Extention Global Roaming Assurance and Service Ana",
      "currency_diskon": 3,
      "diskon": "0",
      "delivery_order": "-",
      "sn": "-",
      "file": "2016_05_11_eHOP160483_Global_Roaming.pdf,"
    }
  ]
}


mock.onGet('/api/purchases').reply(() => {
  return [200, data.all]
})
