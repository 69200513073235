import mock from '@/fake-db/mock.js'

const data = {
  attendance: {
    analyticsData: [
      {
        'customerType': 'Tepat Waktu',
        'counts': 890,
        'color': 'primary'
      },
      {
        'customerType': 'Telat',
        'counts': 258,
        'color': 'warning'
      },
      {
        'customerType': 'Izin Telat ',
        'counts': 149,
        'color': 'danger'
      },
      {
        'customerType': 'Izin Pulang Cepat ',
        'counts': 131,
        'color': 'danger'
      }
    ],
    series: [890, 258, 149, 131]
  },
}


mock.onGet('/api/widgets/analytics/attendance').reply(() => {
  return [200, data.attendance]
})
