/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {

  fetchTickets({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/trouble-ticket?status=${payload.status}`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchTicketsByPriority({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/trouble-ticket?priority=${payload.priority}`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchTicketsByProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/trouble-ticket?product=${payload.pid}`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  detailItem({commit}, payload) {
    let endpoint = `/technical/ticketing/trouble-ticket/${payload.id}`
    
    return new Promise((resolve, reject) => {
      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEM', response.data.values)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  storeItem({commit}, payload) {
    let endpoint = `/technical/ticketing/trouble-ticket`

    return new Promise((resolve, reject) => {
      axios.post(endpoint, payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error);
        })
    })
  },

  editItem({commit}, payload) {
    let endpoint = `/technical/ticketing/trouble-ticket`

    return new Promise((resolve, reject) => {
      axios.post(endpoint, payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateItem({commit}, payload) {
    let endpoint = `/technical/ticketing/trouble-ticket/${payload.id}`

    return new Promise((resolve, reject) => {
      axios.post(endpoint, payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeItem({commit}, payload) {
    let endpoint = `/technical/ticketing/trouble-ticket/${payload.id}`
    
    return new Promise((resolve, reject) => {
      axios.delete(endpoint)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
