/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {

  fetchDeadlineTicket({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/dashboard/deadline-ticket`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchReportTicket({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/dashboard/report-ticket-graph/${payload.month}/${payload.year}`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchStatusWarranty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/status-warranty`

      axios.get(endpoint)
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTicketByPriority({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/dashboard/ticket-priority`

      axios.get(endpoint)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTicketByStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/dashboard/ticket-status`

      axios.get(endpoint)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTicketGraphByProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/dashboard/ticket-graph-product/${payload.filter}`

      axios.get(endpoint)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTicketGraphByCompany({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/ticketing/dashboard/ticket-graph-company/${payload.filter}`

      axios.get(endpoint)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
