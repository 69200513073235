/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  storeBrand({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/technical/sparepart/brand", payload)
        .then((resp) => {
          commit("SET_ITEMS", resp.data.values);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateBrand({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/technical/sparepart/brand/${payload.id}`, payload.body)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteBrand({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/technical/sparepart/brand/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
