/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {

  // Fetch
  fetchItems ({ commit }, payload) {

    let params = {};

    if (payload) {
      if (payload.params)
        params = payload.params;
    }

    return new Promise((resolve, reject) => {
      axios.get('/proforma-invoices', { params })
        .then((response) => {
          console.log(response);
          commit('SET_ITEMS', response.data.values)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

    detailItem ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/proforma-invoices/${payload.id}`, {  })
                .then((response) => {
                    commit('SET_ITEM', response.data.values)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    storeItem ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/proforma-invoices', payload.body)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    updateItem ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/proforma-invoices/${payload.id}`, payload.body)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    removeItem ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`/proforma-invoices/${payload}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    }

}
