import mock from '@/fake-db/mock.js'

const data = {
  all: [
    {
      "id": 1,
      "id_number": 1,
      "id_user": 0,
      "tanggal": "02-01-2014",
      "perihal": "Keterangan",
      "nomor_po": "-",
      "tanggal_po": "-",
      "untuk": "PT. XL Axiata",
      "up": "-",
      "dari": "Admin",
      "keterangan": "Surat Keterangan Pembuatan ID Card XL Taufik Hidayat",
      "lokasi_file": "-",
      "note_refer_to": "-",
      "file": "surat_keluar_2015_1.pdf",
      "number": {
        "id": 1627,
        "number": 23,
        "code": "QN",
        "month": "I",
        "year": 2020
      },
      "user": {
        "id": 21,
        "nik": 49315029,
        "username": "andini",
        "password": "201ceb0536fe893705ca11d397a4f63ef0e38226",
        "active": 1,
        "access": 2,
        "is_login": 1,
        "last_activity": "2020-08-18 08:50:04",
        "location": "/presence",
        "picture": "user21.JPG",
        "fingerprint": "9736a586e3e9c0e2724238ad56eb992a7904bfa0",
        "token": "5d4aa",
        "keylogin": null,
        "chatid": "666399919",
        "employee": {
            "nik": 49315029,
            "id_jabatan": 4,
            "name": "Andini Aristuti",
            "id_card_type": "",
            "id_card_number": "-",
            "place_birth": "Tasikmalaya",
            "date_birth": "14-04-1993",
            "sex": 0,
            "address": "Perum. Taman Tridaya Indah Blok E6/11\r\nJl. Melati Raya RT. 001 RW. 010\r\nTambun Selatan - Bekasi ",
            "postal_code": 17510,
            "city": "-",
            "phone": "-",
            "mobile1": "6285691227114",
            "mobile2": "-",
            "mobile3": "-",
            "email": "-",
            "foto": ""
          }
      }
    },
    {
      "id": 2,
      "id_number": 2,
      "id_user": 0,
      "tanggal": "02-01-2014",
      "perihal": "Permohonan",
      "nomor_po": "-",
      "tanggal_po": "-",
      "untuk": "Kepala Kantor Pelayanan Pajak Pratama Jakarta Tanah Abang Dua",
      "up": "",
      "dari": "Admin",
      "keterangan": "Pemberitahuan Nomor Seri Faktur Pajak Yang Tidak Digunakan ",
      "lokasi_file": "-",
      "note_refer_to": "-",
      "file": "surat_keluar_2015_2.pdf",
      "number": {
        "id": 1627,
        "number": 23,
        "code": "QN",
        "month": "I",
        "year": 2020
      },
      "user": {
        "id": 21,
        "nik": 49315029,
        "username": "andini",
        "password": "201ceb0536fe893705ca11d397a4f63ef0e38226",
        "active": 1,
        "access": 2,
        "is_login": 1,
        "last_activity": "2020-08-18 08:50:04",
        "location": "/presence",
        "picture": "user21.JPG",
        "fingerprint": "9736a586e3e9c0e2724238ad56eb992a7904bfa0",
        "token": "5d4aa",
        "keylogin": null,
        "chatid": "666399919",
        "employee": {
            "nik": 49315029,
            "id_jabatan": 4,
            "name": "Andini Aristuti",
            "id_card_type": "",
            "id_card_number": "-",
            "place_birth": "Tasikmalaya",
            "date_birth": "14-04-1993",
            "sex": 0,
            "address": "Perum. Taman Tridaya Indah Blok E6/11\r\nJl. Melati Raya RT. 001 RW. 010\r\nTambun Selatan - Bekasi ",
            "postal_code": 17510,
            "city": "-",
            "phone": "-",
            "mobile1": "6285691227114",
            "mobile2": "-",
            "mobile3": "-",
            "email": "-",
            "foto": ""
          }
      }
    },
    {
      "id": 3,
      "id_number": 3,
      "id_user": 0,
      "tanggal": "05-01-2014",
      "perihal": "Keterangan",
      "nomor_po": "-",
      "tanggal_po": "-",
      "untuk": "Bank BRI",
      "up": "",
      "dari": "Admin",
      "keterangan": "Surat Keterangan Kerja Untuk Pembuatan Rekening Bank Mandiri a.n Helmi Mulyana",
      "lokasi_file": "-",
      "note_refer_to": "-",
      "file": "surat_keluar_2015_3.pdf",
      "number": {
        "id": 1627,
        "number": 23,
        "code": "QN",
        "month": "I",
        "year": 2020
      },
      "user": {
        "id": 21,
        "nik": 49315029,
        "username": "andini",
        "password": "201ceb0536fe893705ca11d397a4f63ef0e38226",
        "active": 1,
        "access": 2,
        "is_login": 1,
        "last_activity": "2020-08-18 08:50:04",
        "location": "/presence",
        "picture": "user21.JPG",
        "fingerprint": "9736a586e3e9c0e2724238ad56eb992a7904bfa0",
        "token": "5d4aa",
        "keylogin": null,
        "chatid": "666399919",
        "employee": {
            "nik": 49315029,
            "id_jabatan": 4,
            "name": "Andini Aristuti",
            "id_card_type": "",
            "id_card_number": "-",
            "place_birth": "Tasikmalaya",
            "date_birth": "14-04-1993",
            "sex": 0,
            "address": "Perum. Taman Tridaya Indah Blok E6/11\r\nJl. Melati Raya RT. 001 RW. 010\r\nTambun Selatan - Bekasi ",
            "postal_code": 17510,
            "city": "-",
            "phone": "-",
            "mobile1": "6285691227114",
            "mobile2": "-",
            "mobile3": "-",
            "email": "-",
            "foto": ""
          }
      }
    },
    {
      "id": 4,
      "id_number": 4,
      "id_user": 0,
      "tanggal": "05-01-2014",
      "perihal": "Permohonan",
      "nomor_po": "-",
      "tanggal_po": "-",
      "untuk": "Kepala Kantor Pelayanan Pajak Pratama Jakarta Tanah Abang Dua",
      "up": "",
      "dari": "Admin",
      "keterangan": "Permintaan Nomor Seri Faktur Pajak sejumlah 8 buah",
      "lokasi_file": "-",
      "note_refer_to": "-",
      "file": "surat_keluar_2015_4.pdf",
      "number": {
        "id": 1627,
        "number": 23,
        "code": "QN",
        "month": "I",
        "year": 2020
      },
      "user": {
        "id": 21,
        "nik": 49315029,
        "username": "andini",
        "password": "201ceb0536fe893705ca11d397a4f63ef0e38226",
        "active": 1,
        "access": 2,
        "is_login": 1,
        "last_activity": "2020-08-18 08:50:04",
        "location": "/presence",
        "picture": "user21.JPG",
        "fingerprint": "9736a586e3e9c0e2724238ad56eb992a7904bfa0",
        "token": "5d4aa",
        "keylogin": null,
        "chatid": "666399919",
        "employee": {
            "nik": 49315029,
            "id_jabatan": 4,
            "name": "Andini Aristuti",
            "id_card_type": "",
            "id_card_number": "-",
            "place_birth": "Tasikmalaya",
            "date_birth": "14-04-1993",
            "sex": 0,
            "address": "Perum. Taman Tridaya Indah Blok E6/11\r\nJl. Melati Raya RT. 001 RW. 010\r\nTambun Selatan - Bekasi ",
            "postal_code": 17510,
            "city": "-",
            "phone": "-",
            "mobile1": "6285691227114",
            "mobile2": "-",
            "mobile3": "-",
            "email": "-",
            "foto": ""
          }
      }
    },
    {
      "id": 5,
      "id_number": 5,
      "id_user": 0,
      "tanggal": "12-Dec-14",
      "perihal": "Surat permohonan pembayaran ",
      "nomor_po": "BERKAH_179/PO/XII/14",
      "tanggal_po": "11-Dec-14",
      "untuk": "CV. Berkah Indo Makmur Abadi",
      "up": "-",
      "dari": "Admin ",
      "keterangan": "Penagihan DP 50% VCL-16E1 + Ethernet PDH Optical Multiplexer Connector SC",
      "lokasi_file": "Invoice to customer",
      "note_refer_to": "-",
      "file": "",
      "number": {
        "id": 1627,
        "number": 23,
        "code": "QN",
        "month": "I",
        "year": 2020
      },
      "user": {
        "id": 21,
        "nik": 49315029,
        "username": "andini",
        "password": "201ceb0536fe893705ca11d397a4f63ef0e38226",
        "active": 1,
        "access": 2,
        "is_login": 1,
        "last_activity": "2020-08-18 08:50:04",
        "location": "/presence",
        "picture": "user21.JPG",
        "fingerprint": "9736a586e3e9c0e2724238ad56eb992a7904bfa0",
        "token": "5d4aa",
        "keylogin": null,
        "chatid": "666399919",
        "employee": {
            "nik": 49315029,
            "id_jabatan": 4,
            "name": "Andini Aristuti",
            "id_card_type": "",
            "id_card_number": "-",
            "place_birth": "Tasikmalaya",
            "date_birth": "14-04-1993",
            "sex": 0,
            "address": "Perum. Taman Tridaya Indah Blok E6/11\r\nJl. Melati Raya RT. 001 RW. 010\r\nTambun Selatan - Bekasi ",
            "postal_code": 17510,
            "city": "-",
            "phone": "-",
            "mobile1": "6285691227114",
            "mobile2": "-",
            "mobile3": "-",
            "email": "-",
            "foto": ""
          }
      }
    },
    {
      "id": 6,
      "id_number": 6,
      "id_user": 0,
      "tanggal": "12-Dec-14",
      "perihal": "Invoice ",
      "nomor_po": "BERKAH_179/PO/XII/14",
      "tanggal_po": "11-Dec-14",
      "untuk": "CV. Berkah Indo Makmur Abadi",
      "up": "-",
      "dari": "Admin ",
      "keterangan": "Penagihan DP 50% VCL-16E1 + Ethernet PDH Optical Multiplexer Connector SC",
      "lokasi_file": "Invoice to customer",
      "note_refer_to": "-",
      "file": "",
      "number": {
        "id": 1627,
        "number": 23,
        "code": "QN",
        "month": "I",
        "year": 2020
      },
      "user": {
        "id": 21,
        "nik": 49315029,
        "username": "andini",
        "password": "201ceb0536fe893705ca11d397a4f63ef0e38226",
        "active": 1,
        "access": 2,
        "is_login": 1,
        "last_activity": "2020-08-18 08:50:04",
        "location": "/presence",
        "picture": "user21.JPG",
        "fingerprint": "9736a586e3e9c0e2724238ad56eb992a7904bfa0",
        "token": "5d4aa",
        "keylogin": null,
        "chatid": "666399919",
        "employee": {
            "nik": 49315029,
            "id_jabatan": 4,
            "name": "Andini Aristuti",
            "id_card_type": "",
            "id_card_number": "-",
            "place_birth": "Tasikmalaya",
            "date_birth": "14-04-1993",
            "sex": 0,
            "address": "Perum. Taman Tridaya Indah Blok E6/11\r\nJl. Melati Raya RT. 001 RW. 010\r\nTambun Selatan - Bekasi ",
            "postal_code": 17510,
            "city": "-",
            "phone": "-",
            "mobile1": "6285691227114",
            "mobile2": "-",
            "mobile3": "-",
            "email": "-",
            "foto": ""
          }
      }
    },
    {
      "id": 7,
      "id_number": 7,
      "id_user": 0,
      "tanggal": "12-Dec-14",
      "perihal": "Kuitansi",
      "nomor_po": "BERKAH_179/PO/XII/14",
      "tanggal_po": "11-Dec-14",
      "untuk": "CV. Berkah Indo Makmur Abadi",
      "up": "-",
      "dari": "Admin ",
      "keterangan": "Penagihan DP 50% VCL-16E1 + Ethernet PDH Optical Multiplexer Connector SC",
      "lokasi_file": "Invoice to customer",
      "note_refer_to": "-",
      "file": "",
      "number": {
        "id": 1627,
        "number": 23,
        "code": "QN",
        "month": "I",
        "year": 2020
      },
      "user": {
        "id": 21,
        "nik": 49315029,
        "username": "andini",
        "password": "201ceb0536fe893705ca11d397a4f63ef0e38226",
        "active": 1,
        "access": 2,
        "is_login": 1,
        "last_activity": "2020-08-18 08:50:04",
        "location": "/presence",
        "picture": "user21.JPG",
        "fingerprint": "9736a586e3e9c0e2724238ad56eb992a7904bfa0",
        "token": "5d4aa",
        "keylogin": null,
        "chatid": "666399919",
        "employee": {
            "nik": 49315029,
            "id_jabatan": 4,
            "name": "Andini Aristuti",
            "id_card_type": "",
            "id_card_number": "-",
            "place_birth": "Tasikmalaya",
            "date_birth": "14-04-1993",
            "sex": 0,
            "address": "Perum. Taman Tridaya Indah Blok E6/11\r\nJl. Melati Raya RT. 001 RW. 010\r\nTambun Selatan - Bekasi ",
            "postal_code": 17510,
            "city": "-",
            "phone": "-",
            "mobile1": "6285691227114",
            "mobile2": "-",
            "mobile3": "-",
            "email": "-",
            "foto": ""
          }
      }
    },
    {
      "id": 8,
      "id_number": 8,
      "id_user": 0,
      "tanggal": "13-Dec-14",
      "perihal": "Kuitansi",
      "nomor_po": "BERKAH_179/PO/XII/14",
      "tanggal_po": "11-Dec-14",
      "untuk": "CV. Berkah Indo Makmur Abadi",
      "up": "-",
      "dari": "Admin ",
      "keterangan": "Penagihan Shipping Cost VCL-16E1 + Ethernet PDH Optical Multiplexer Connector SC",
      "lokasi_file": "Invoice to customer",
      "note_refer_to": "-",
      "file": "",
      "number": {
        "id": 1627,
        "number": 23,
        "code": "QN",
        "month": "I",
        "year": 2020
      },
      "user": {
        "id": 21,
        "nik": 49315029,
        "username": "andini",
        "password": "201ceb0536fe893705ca11d397a4f63ef0e38226",
        "active": 1,
        "access": 2,
        "is_login": 1,
        "last_activity": "2020-08-18 08:50:04",
        "location": "/presence",
        "picture": "user21.JPG",
        "fingerprint": "9736a586e3e9c0e2724238ad56eb992a7904bfa0",
        "token": "5d4aa",
        "keylogin": null,
        "chatid": "666399919",
        "employee": {
            "nik": 49315029,
            "id_jabatan": 4,
            "name": "Andini Aristuti",
            "id_card_type": "",
            "id_card_number": "-",
            "place_birth": "Tasikmalaya",
            "date_birth": "14-04-1993",
            "sex": 0,
            "address": "Perum. Taman Tridaya Indah Blok E6/11\r\nJl. Melati Raya RT. 001 RW. 010\r\nTambun Selatan - Bekasi ",
            "postal_code": 17510,
            "city": "-",
            "phone": "-",
            "mobile1": "6285691227114",
            "mobile2": "-",
            "mobile3": "-",
            "email": "-",
            "foto": ""
          }
      }
    },
    {
      "id": 9,
      "id_number": 9,
      "id_user": 0,
      "tanggal": "06-01-2015",
      "perihal": "Surat Kuasa",
      "nomor_po": "-",
      "tanggal_po": "-",
      "untuk": "-",
      "up": "-",
      "dari": "-",
      "keterangan": "Untuk keperluan print tagihan XL bulan Oktober s/d Desember 2014 untuk nomor 0858 – 1135 3469",
      "lokasi_file": "-",
      "note_refer_to": "-",
      "file": "surat_keluar_2015_9.pdf",
      "number": {
        "id": 1627,
        "number": 23,
        "code": "QN",
        "month": "I",
        "year": 2020
      },
      "user": {
        "id": 21,
        "nik": 49315029,
        "username": "andini",
        "password": "201ceb0536fe893705ca11d397a4f63ef0e38226",
        "active": 1,
        "access": 2,
        "is_login": 1,
        "last_activity": "2020-08-18 08:50:04",
        "location": "/presence",
        "picture": "user21.JPG",
        "fingerprint": "9736a586e3e9c0e2724238ad56eb992a7904bfa0",
        "token": "5d4aa",
        "keylogin": null,
        "chatid": "666399919",
        "employee": {
            "nik": 49315029,
            "id_jabatan": 4,
            "name": "Andini Aristuti",
            "id_card_type": "",
            "id_card_number": "-",
            "place_birth": "Tasikmalaya",
            "date_birth": "14-04-1993",
            "sex": 0,
            "address": "Perum. Taman Tridaya Indah Blok E6/11\r\nJl. Melati Raya RT. 001 RW. 010\r\nTambun Selatan - Bekasi ",
            "postal_code": 17510,
            "city": "-",
            "phone": "-",
            "mobile1": "6285691227114",
            "mobile2": "-",
            "mobile3": "-",
            "email": "-",
            "foto": ""
          }
      }
    },
    {
      "id": 10,
      "id_number": 10,
      "id_user": 0,
      "tanggal": "06-01-2015",
      "perihal": "Surat Kuasa",
      "nomor_po": "-",
      "tanggal_po": "-",
      "untuk": "-",
      "up": "-",
      "dari": "-",
      "keterangan": "Untuk keperluan print tagihan Telkomsel bulan Oktober s/d Desember 2014, nomor  0812 - 1030 4413",
      "lokasi_file": "-",
      "note_refer_to": "-",
      "file": "",
      "number": {
        "id": 1627,
        "number": 23,
        "code": "QN",
        "month": "I",
        "year": 2020
      },
      "user": {
        "id": 21,
        "nik": 49315029,
        "username": "andini",
        "password": "201ceb0536fe893705ca11d397a4f63ef0e38226",
        "active": 1,
        "access": 2,
        "is_login": 1,
        "last_activity": "2020-08-18 08:50:04",
        "location": "/presence",
        "picture": "user21.JPG",
        "fingerprint": "9736a586e3e9c0e2724238ad56eb992a7904bfa0",
        "token": "5d4aa",
        "keylogin": null,
        "chatid": "666399919",
        "employee": {
            "nik": 49315029,
            "id_jabatan": 4,
            "name": "Andini Aristuti",
            "id_card_type": "",
            "id_card_number": "-",
            "place_birth": "Tasikmalaya",
            "date_birth": "14-04-1993",
            "sex": 0,
            "address": "Perum. Taman Tridaya Indah Blok E6/11\r\nJl. Melati Raya RT. 001 RW. 010\r\nTambun Selatan - Bekasi ",
            "postal_code": 17510,
            "city": "-",
            "phone": "-",
            "mobile1": "6285691227114",
            "mobile2": "-",
            "mobile3": "-",
            "email": "-",
            "foto": ""
          }
      }
    }
  ]
}


mock.onGet('/api/outgoing-letters').reply(() => {
  return [200, data.all]
})
