import mock from '@/fake-db/mock.js'

const data = {
  all: [
    {
      "id": 1,
      "tanggal": "10-05-2019",
      "ket_penagihan": "Penagihan DP 50% : Spare part Wheel Holder Half Shell dll",
      "date_issued": "10-05-2019",
      "date_due": "24-05-2019",
      "customer": "PT. Bestari Mulia",
      "no_npwp_customer": "02.638.198.8-603.000",
      "no_po_kontrak": "19210948/XL",
      "do_number": "202/DO-AST/VII/2019",
      "do_date": "16-07-2019",
      "spp_number": "-",
      "invoice_number": "105Rev/IV-AST/V/2019",
      "invoice_date": "10-05-2019",
      "matauang_total_tagihan": 3,
      "nilai_total_tagihan": "159500000",
      "matauang_invoice": 3,
      "invoice": "145000000",
      "matauang_pajak": 3,
      "pajak": "14500000",
      "keterangan": "Sudah termasuk VAT 10%, tetapi tidak release faktur pajak.",
      "rekening_pembayaran": "Bank BRI",
      "file": "proforma_2019_1.pdf"
    },
    {
      "id": 2,
      "tanggal": "12-06-2019",
      "ket_penagihan": "Penagihan DP 50% : Arm Holder, Coiler Block, dan Rocker Lever Pin",
      "date_issued": "12-06-2019",
      "date_due": "18-06-2019",
      "customer": "PT. Royal Abadi Sejahtera",
      "no_npwp_customer": "01.455.909.0-441.000",
      "no_po_kontrak": "19114320/XL",
      "do_number": "203/DO-AST/VII/2019",
      "do_date": "18-07-2019",
      "spp_number": "",
      "invoice_number": "157/IV-AST/VI/2019",
      "invoice_date": "12-06-2019",
      "matauang_total_tagihan": 3,
      "nilai_total_tagihan": "46200000",
      "matauang_invoice": 3,
      "invoice": "42000000",
      "matauang_pajak": 3,
      "pajak": "4200000",
      "keterangan": "Sudah termasuk VAT 10%, tetapi tidak release faktur pajak.",
      "rekening_pembayaran": "Bank BRI",
      "file": "proforma_2019_2.pdf"
    },
    {
      "id": 3,
      "tanggal": "07-08-2019",
      "ket_penagihan": "Penagihan DP 50% : Electrode Support, Insert Blade, dan Wire Feed Roller",
      "date_issued": "07-08-2019",
      "date_due": "20-09-2019",
      "customer": "PT. Royal Abadi Sejahtera",
      "no_npwp_customer": "01.455.909.0-441.000",
      "no_po_kontrak": "19115539/XL",
      "do_number": "261/DO-AST/IX/2019",
      "do_date": "20-09-2019",
      "spp_number": "",
      "invoice_number": "218/IV-AST/IX/2019",
      "invoice_date": "07-08-2019",
      "matauang_total_tagihan": 3,
      "nilai_total_tagihan": "16225000",
      "matauang_invoice": 3,
      "invoice": "1475000",
      "matauang_pajak": 3,
      "pajak": "14750000",
      "keterangan": "Sudah termasuk VAT 10%, tetapi tidak release faktur pajak.",
      "rekening_pembayaran": "Bank BRI",
      "file": "proforma_2019_3.pdf"
    },
    {
      "id": 4,
      "tanggal": "19-06-2019",
      "ket_penagihan": "Penagihan DP 70% : Cutting Nipper Shaft Trust Head, dll",
      "date_issued": "19-06-2019",
      "date_due": "27-06-2019",
      "customer": "PT. Royal Abadi Sejahtera",
      "no_npwp_customer": "01.455.909.0-441.000",
      "no_po_kontrak": "19114377/XL",
      "do_number": "227/DO-AST/VIII/2019 dan 279/DO-AST/X/2019 (tgl 07 Okt 2019)",
      "do_date": "14-08-2019",
      "spp_number": "",
      "invoice_number": "162/IV-AST/VI/2019",
      "invoice_date": "19-06-2019",
      "matauang_total_tagihan": 3,
      "nilai_total_tagihan": "361793133.22",
      "matauang_invoice": 3,
      "invoice": "328902848.38",
      "matauang_pajak": 3,
      "pajak": "32890284.84",
      "keterangan": "Sudah termasuk VAT 10%, tetapi tidak release faktur pajak.",
      "rekening_pembayaran": "Bank BRI",
      "file": "proforma_2019_4.pdf"
    },
    {
      "id": 5,
      "tanggal": "19-06-2019",
      "ket_penagihan": "Penagihan DP 70% : Anti Vibration Device 6-7 Turn, dll",
      "date_issued": "19-06-2019",
      "date_due": "27-06-2019",
      "customer": "PT. Bestari Mulia",
      "no_npwp_customer": "02.638.198.8-603.000",
      "no_po_kontrak": "19211432/XL",
      "do_number": "229/DO-AST/VIII/2019 dan 278/DO-AST/X/2019 (tgl 07 Okt 2019)",
      "do_date": "14-08-2019",
      "spp_number": "",
      "invoice_number": "161/IV-AST/VI/2019",
      "invoice_date": "19-06-2019",
      "matauang_total_tagihan": 3,
      "nilai_total_tagihan": "41473133.33",
      "matauang_invoice": 3,
      "invoice": "37702848.48",
      "matauang_pajak": 3,
      "pajak": "3770284.85",
      "keterangan": "Sudah termasuk VAT 10%, tetapi tidak release faktur pajak.",
      "rekening_pembayaran": "Bank BRI",
      "file": "proforma_2019_5.pdf"
    },
    {
      "id": 6,
      "tanggal": "19-06-2019",
      "ket_penagihan": "Penagihan DP 70% : Cutting Nipper Shaft Trust Head, dll",
      "date_issued": "19-06-2019",
      "date_due": "27-06-2019",
      "customer": "PT. Anugrah Cemerlang Abadi",
      "no_npwp_customer": "02.492.888.9-072.000",
      "no_po_kontrak": "19510959/XL",
      "do_number": "228/DO-AST/VIII/2019 dan 277/DO-AST/X/2019 (tgl 07 Okt 2019)",
      "do_date": "14-08-2019",
      "spp_number": "",
      "invoice_number": "160/IV-AST/VI/2019",
      "invoice_date": "19-06-2019",
      "matauang_total_tagihan": 3,
      "nilai_total_tagihan": "86733733.32",
      "matauang_invoice": 3,
      "invoice": "78848848.48",
      "matauang_pajak": 3,
      "pajak": "7884884.85",
      "keterangan": "Sudah termasuk VAT 10%, tetapi tidak release faktur pajak.",
      "rekening_pembayaran": "Bank BRI",
      "file": "proforma_2019_6.pdf"
    },
    {
      "id": 7,
      "tanggal": "23-09-2019",
      "ket_penagihan": "Penagihan DP 50% : Electrode High Column & Wire Feed Roller",
      "date_issued": "23-09-2019",
      "date_due": "08-10-2019",
      "customer": "PT. Royal Abadi Sejahtera",
      "no_npwp_customer": "01.455.909.0-441.000",
      "no_po_kontrak": "19117114/XL",
      "do_number": "316/DO-AST/XI/2019",
      "do_date": "05-11-2019",
      "spp_number": "",
      "invoice_number": "262/IV-AST/IX/2019",
      "invoice_date": "23-09-2019",
      "matauang_total_tagihan": 3,
      "nilai_total_tagihan": "22000000",
      "matauang_invoice": 3,
      "invoice": "20000000",
      "matauang_pajak": 3,
      "pajak": "2000000",
      "keterangan": "Sudah termasuk VAT 10%, tetapi tidak release faktur pajak.",
      "rekening_pembayaran": "Bank BRI",
      "file": "proforma_2019_7.pdf"
    },
    {
      "id": 8,
      "tanggal": "07-11-2019",
      "ket_penagihan": "Penagihan DP 50% : Slinding Key Spring, dll",
      "date_issued": "07-11-2019",
      "date_due": "14-11-2019",
      "customer": "PT. Scherling Indonesia",
      "no_npwp_customer": "02.568.722.9-424.000",
      "no_po_kontrak": "08-XIX-PM/SCH",
      "do_number": "351/DO-AST/XII/2019",
      "do_date": "06-12-2019",
      "spp_number": "",
      "invoice_number": "321/IV-AST/XI/2019",
      "invoice_date": "07-11-2019",
      "matauang_total_tagihan": 3,
      "nilai_total_tagihan": "54565500",
      "matauang_invoice": 3,
      "invoice": "49605000",
      "matauang_pajak": 3,
      "pajak": "4960500",
      "keterangan": "Sudah termasuk VAT 10%, tetapi tidak release faktur pajak.",
      "rekening_pembayaran": "Bank BRI",
      "file": "proforma_2019_8.pdf"
    }
  ]
}


mock.onGet('/api/proforma-invoices').reply(() => {
  return [200, data.all]
})
