/*=========================================================================================
  File Name: moduleEmail.js
  Description: Email Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleTicketState.js'
import mutations from './moduleTicketMutations.js'
import actions from './moduleTicketActions.js'
import getters from './moduleTicketGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
