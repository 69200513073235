import mock from '@/fake-db/mock.js'

const data = {
  all: []
}


mock.onGet('/api/quotations').reply(() => {
  return [200, data.all]
})
