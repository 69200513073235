/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  getEquipments({ commit }, status) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/technical/equipment?status=${status}`)
        .then((response) => {
          commit("SET_ITEMS", response.data.values);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  showEquipment({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/technical/equipment/${id}`)
        .then((resp) => {
          commit("SET_ITEM", resp.data.values);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  storeEquipment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let endpoint = `/technical/equipment?status=${payload.status}`;

      axios
        .post(endpoint, payload.body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEquipment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/technical/equipment/${payload.id}?status=${payload.status}`,
          payload.body
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/technical/equipment/${payload.id}/upload?status=${payload.status}`,
          payload.body
        )
        .then((resp) => {
          resolve(resp);
        });
    }).catch((err) => {
      reject(err);
    });
  },
  deleteEquipment({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/technical/equipment/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
